import React, { forwardRef } from 'react';
import { Box, ButtonBase, ButtonBaseProps, Typography, makeStyles, useTheme } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import { useAppSelector } from 'reducers/rootReducer';

const useStyles = makeStyles((theme) => ({
    buttonBase: {
        position: 'relative',
        '&:hover $label': {
            opacity: 0.7,
        },
        '&:hover $hideArrow': {
            opacity: 0,
        },
        '&:hover $highlightArrow': {
            color: theme.palette.text.secondary,
            opacity: 1,
        },
        '&:focus $label': {
            color: theme.palette.text.secondary,
        },
    },
    nonSortable: {
        cursor: 'default',
    },
    label: {},
    hideArrow: {},
    highlightArrow: {},
}));

export const CenteredIcon: React.FC<{
    children: JSX.Element;
    outerStyle?: React.CSSProperties;
    innerStyle?: React.CSSProperties;
}> = ({ children, outerStyle, innerStyle }) => {
    return (
        <span style={{ position: 'relative', paddingRight: '2rem', ...outerStyle }}>
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '1rem',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    ...innerStyle,
                }}
            >
                {children}
            </div>
        </span>
    );
};

export const SortLabel: React.FC<{
    label: React.ReactNode;
    sortable?: boolean;
    active?: boolean;
    direction?: 'asc' | 'desc';
    ButtonProps?: ButtonBaseProps;
    ref?: React.Ref<HTMLButtonElement>;
}> = forwardRef(({ label, active, direction, ButtonProps, sortable = true }, ref) => {
    const printMode = useAppSelector((state) => state.printMode);
    const nextDirection = !active ? 'asc' : direction;
    const classes = useStyles();
    const theme = useTheme();

    return (
        <ButtonBase ref={ref} className={sortable ? classes.buttonBase : classes.nonSortable} {...ButtonProps}>
            <Typography
                className={classes.label}
                style={{
                    fontWeight: 'bold',
                    fontSize: printMode ? '11px' : theme.typography.fontSize,
                }}
            >
                {label}
            </Typography>
            <CenteredIcon>
                {!printMode &&
                    sortable &&
                    (!active ? (
                        <Box style={{ display: 'inline-grid' }}>
                            <ArrowDropUp
                                className={nextDirection === 'desc' ? classes.hideArrow : classes.highlightArrow}
                                viewBox="1 -9 24 24"
                                color="disabled"
                            />
                            <ArrowDropDown
                                className={nextDirection === 'asc' ? classes.hideArrow : classes.highlightArrow}
                                viewBox="1 9 24 24"
                                color="disabled"
                            />
                        </Box>
                    ) : direction === 'asc' ? (
                        <ArrowDropUp />
                    ) : (
                        <ArrowDropDown />
                    ))}
            </CenteredIcon>
        </ButtonBase>
    );
});
