import React, { useCallback, useMemo, useState } from 'react';
import { DecisionTable } from './domain';
import { Add, AddCircle, Clear, Delete, RemoveCircle } from '@material-ui/icons';
import {
    Button,
    DialogActions,
    DialogContent,
    FormControl,
    IconButton,
    InputLabel,
    TextField,
    Typography,
    useTheme,
} from '@material-ui/core';
import produce from 'immer';
import { MoveDown, MoveUp } from '@mui/icons-material';
import { Dialog } from '@mui/material';
import { Formik } from 'formik';
import CasetivitySelect from 'components/CasetivitySelect';

const ControlledDecisionTableEditor = (props: {
    state: DecisionTable;
    setState: React.Dispatch<React.SetStateAction<DecisionTable>>;
}) => {
    const { state, setState } = props;
    const theme = useTheme();
    const border = `1px solid ${theme.palette.divider}`;
    type EditColumn =
        | {
              type: 'inputExpressions' | 'outputExpressions';
              toInsert: boolean;
              i: number; // the index we are editing if toInsert is false - the index to insert into if true
          }
        | false;
    const [editColumn, setEditColumn] = useState<EditColumn>(false);

    const newColId = useMemo(() => {
        if (!editColumn) {
            return null;
        }
        return (Math.max(...state[editColumn.type]?.map((ie) => parseInt(ie.id))) ?? 0) + '';
    }, [editColumn, state]);

    const deleteColumn = useCallback(
        (type: 'inputExpressions' | 'outputExpressions', i) => {
            setState((state) => {
                return produce(state, (draft) => {
                    draft[type].splice(i, 1);
                });
            });
        },
        [setState],
    );
    return (
        <div>
            <div style={{ display: 'flex' }}>
                <div style={{ margin: '.5em' }}>
                    <TextField
                        margin="normal"
                        size="small"
                        value={state.key}
                        onChange={(e) =>
                            setState((state) => ({
                                ...state,
                                key: e.target.value,
                            }))
                        }
                        label="Key"
                    />
                </div>
                <div style={{ margin: '.5em' }}>
                    <TextField
                        margin="normal"
                        size="small"
                        value={state.name}
                        onChange={(e) =>
                            setState((state) => ({
                                ...state,
                                name: e.target.value,
                            }))
                        }
                        label="Name"
                    />
                </div>
                <div style={{ flex: 1, margin: '.5em' }}>
                    <TextField
                        fullWidth
                        margin="normal"
                        multiline
                        size="small"
                        value={state.description}
                        onChange={(e) =>
                            setState((state) => ({
                                ...state,
                                description: e.target.value,
                            }))
                        }
                        label="Description"
                    />
                </div>
            </div>

            {!!editColumn && (
                <Dialog open onClose={() => setEditColumn(false)}>
                    <Formik<DecisionTable['inputExpressions'][number]>
                        initialValues={
                            editColumn.toInsert
                                ? {
                                      id: newColId,
                                      variableId: '',
                                      label: '',
                                      type: 'string',
                                  }
                                : state[editColumn.type][editColumn.i]
                        }
                        validateOnMount
                        validate={(values) => {
                            const res = {};
                            if (!values.variableId) {
                                res['variableId'] = 'Required';
                            }
                            if (!values.type) {
                                res['type'] = 'required';
                            }
                            return res;
                        }}
                        onSubmit={(_values, { setSubmitting }) => {
                            const values = {
                                ..._values,
                                entries: _values.entries?.map((e: string) => e.trim()).filter(Boolean),
                            };

                            setState((state) => {
                                return produce(state, (draft) => {
                                    if (editColumn.toInsert) {
                                        draft[editColumn.type].splice(editColumn.i, 0, values);
                                    } else {
                                        draft[editColumn.type][editColumn.i] = values;
                                    }
                                });
                            });
                            setEditColumn(false);
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                            <form autoComplete="off" onSubmit={handleSubmit}>
                                <div
                                    style={{
                                        margin: '1em',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div>
                                        <Typography variant="h6">Edit {editColumn.type} column</Typography>
                                    </div>
                                    <div>
                                        <IconButton size="small" onClick={() => setEditColumn(false)}>
                                            <Clear />
                                        </IconButton>
                                    </div>
                                </div>
                                <DialogContent>
                                    <div>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.label}
                                            name="label"
                                            label="Label"
                                            error={touched.label && !!errors.label}
                                            helperText={touched.label && errors.label}
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.variableId}
                                            name="variableId"
                                            label="Variable ID"
                                            error={touched.variableId && !!errors.variableId}
                                            helperText={touched.variableId && errors.variableId}
                                        />
                                    </div>
                                    <div>
                                        <FormControl margin="normal" size="small">
                                            <InputLabel shrink={true} id="type-label">
                                                Data Type
                                            </InputLabel>
                                            <CasetivitySelect
                                                value={values.type}
                                                onChange={handleChange}
                                                name="type"
                                                label="Data Type"
                                                SelectDisplayProps={{ 'aria-describedby': 'type-label' }}
                                            >
                                                {({ OptionComponent }) => [
                                                    <OptionComponent key="string" value="string">
                                                        string
                                                    </OptionComponent>,
                                                    <OptionComponent key="number" value="number">
                                                        number
                                                    </OptionComponent>,
                                                    <OptionComponent key="boolean" value="boolean">
                                                        boolean
                                                    </OptionComponent>,
                                                    <OptionComponent key="date" value="date">
                                                        date
                                                    </OptionComponent>,
                                                ]}
                                            </CasetivitySelect>
                                        </FormControl>
                                    </div>
                                    <p>Allowed values (optional):</p>
                                    <ul>
                                        {values.entries?.map((entry, i, arr) => {
                                            return (
                                                <li key={i}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            height: '100%',
                                                        }}
                                                    >
                                                        <TextField
                                                            size="small"
                                                            margin="normal"
                                                            onChange={(e) => {
                                                                const newValue = [...arr];
                                                                newValue.splice(i, 1, e.target.value);
                                                                setFieldValue('entries', newValue);
                                                            }}
                                                            onBlur={(e) => {
                                                                const newValue = [...arr];
                                                                newValue.splice(i, 1, e.target.value);
                                                                setFieldValue('entries', newValue);
                                                            }}
                                                            value={entry ?? ''}
                                                            aria-label="entry"
                                                        />
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => {
                                                                const newValue = [...arr];
                                                                newValue.splice(i, 1);
                                                                setFieldValue('entries', newValue);
                                                            }}
                                                        >
                                                            <Delete fontSize="small" />
                                                        </IconButton>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                    <Button
                                        onClick={() => {
                                            setFieldValue('entries', [...(values.entries ?? []), '']);
                                        }}
                                        endIcon={<Add />}
                                        size="small"
                                    >
                                        Add
                                    </Button>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setEditColumn(false)} size="small">
                                        Cancel
                                    </Button>
                                    <Button
                                        disabled={Object.keys(errors).length > 0}
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        Save
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Formik>
                </Dialog>
            )}
            <table
                style={{
                    borderCollapse: 'collapse',
                    width: '100%',
                }}
            >
                <colgroup style={{ display: 'table-column-group' }}>
                    <col />
                    {state.inputExpressions.map((ie) => {
                        return (
                            <React.Fragment key={ie.id}>
                                <col key={ie.id} />
                                <col key={ie.id} />
                            </React.Fragment>
                        );
                    })}
                    {state.outputExpressions.map((ie) => (
                        <col key={ie.id} />
                    ))}
                    <col /> {/* For actions (delete, move) */}
                </colgroup>
                <thead>
                    <tr>
                        <th style={{ border }}>
                            <div style={{ margin: '0 1em' }}>
                                <FormControl margin="none" size="small">
                                    <InputLabel shrink={true} id="hit-label">
                                        Hit Policy
                                    </InputLabel>
                                    <CasetivitySelect
                                        value={state.hitIndicator}
                                        onChange={(e) =>
                                            setState((state) => ({
                                                ...state,
                                                hitIndicator: e.target.value as any,
                                            }))
                                        }
                                        label="Hit Policy"
                                        SelectDisplayProps={{ 'aria-describedby': 'hit-label' }}
                                    >
                                        {({ OptionComponent }) =>
                                            [
                                                'FIRST',
                                                'ANY',
                                                'UNIQUE',
                                                'PRIORITY',
                                                'RULE ORDER',
                                                'OUTPUT ORDER',
                                                'COLLECT',
                                            ].map((type) => (
                                                <OptionComponent key={type} value={type}>
                                                    {type}
                                                </OptionComponent>
                                            ))
                                        }
                                    </CasetivitySelect>
                                </FormControl>
                            </div>
                        </th>
                        {state.inputExpressions.map((ie, i, arr) => {
                            const isOnlyInput = arr.length === 1;
                            return (
                                <>
                                    <th
                                        style={{
                                            border,
                                            borderRightColor: 'transparent',
                                            backgroundColor: 'rgba(54, 167, 196, 0.1)',
                                        }}
                                    >
                                        {!isOnlyInput && (
                                            <div style={{ float: 'left' }}>
                                                <IconButton
                                                    onClick={() => deleteColumn('inputExpressions', i)}
                                                    color="primary"
                                                    size="small"
                                                >
                                                    <RemoveCircle fontSize="small" />
                                                </IconButton>
                                            </div>
                                        )}
                                        <div style={{ clear: 'both' }}></div>
                                    </th>
                                    <th
                                        style={{
                                            border,
                                            borderLeftColor: 'transparent',
                                            backgroundColor: 'rgba(54, 167, 196, 0.1)',
                                        }}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div style={{ flex: 1, marginRight: '20px' }}>
                                                <p>
                                                    <button
                                                        onClick={() =>
                                                            setEditColumn({
                                                                i,
                                                                toInsert: false,
                                                                type: 'inputExpressions',
                                                            })
                                                        }
                                                        className="casetivity-linkbutton"
                                                        style={{
                                                            fontSize: theme.typography.fontSize,
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {ie.label}
                                                    </button>
                                                </p>
                                                <p>{ie.variableId}</p>
                                                <p>{ie.entries?.join(',')}</p>
                                            </div>
                                            <div style={{ position: 'relative', width: 25 }}>
                                                <span
                                                    style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '.75em',
                                                        transform: 'translate(-50%, -50%)',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <IconButton
                                                        onClick={() =>
                                                            setEditColumn({
                                                                i: i + 1,
                                                                toInsert: true,
                                                                type: 'inputExpressions',
                                                            })
                                                        }
                                                        color="primary"
                                                        size="small"
                                                    >
                                                        <AddCircle fontSize="small" />
                                                    </IconButton>
                                                </span>
                                            </div>
                                        </div>
                                    </th>
                                </>
                            );
                        })}
                        {state.outputExpressions.map((oe, i, arr) => {
                            const isOnlyInput = arr.length === 1;
                            return (
                                <th style={{ border, backgroundColor: 'rgb(243, 243, 243)' }}>
                                    <div style={{ display: 'flex' }}>
                                        {!isOnlyInput && (
                                            <div style={{ position: 'relative', width: 26 }}>
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '1em',
                                                        transform: 'translate(-50%, -50%)',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <IconButton
                                                        onClick={() => deleteColumn('outputExpressions', i)}
                                                        color="primary"
                                                        size="small"
                                                    >
                                                        <RemoveCircle fontSize="small" />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        )}
                                        <div style={{ flex: 1 }}>
                                            <button
                                                onClick={() =>
                                                    setEditColumn({
                                                        i,
                                                        toInsert: false,
                                                        type: 'outputExpressions',
                                                    })
                                                }
                                                className="casetivity-linkbutton"
                                                style={{
                                                    fontSize: theme.typography.fontSize,
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {oe.label || 'New Output'}
                                            </button>
                                            <p>{oe.variableId || 'none'}</p>
                                            <p>{oe.entries?.join(',')}</p>
                                        </div>
                                        <div style={{ position: 'relative', width: 26 }}>
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '1em',
                                                    transform: 'translate(-50%, -50%)',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <IconButton
                                                    onClick={() =>
                                                        setEditColumn({
                                                            i: i + 1,
                                                            toInsert: true,
                                                            type: 'outputExpressions',
                                                        })
                                                    }
                                                    color="primary"
                                                    size="small"
                                                >
                                                    <AddCircle fontSize="small" />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                </th>
                            );
                        })}
                        <th>
                            <span className="casetivity-off-screen">Actions</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {state.rules.map((r, i, arr) => {
                        const operatorHtmlID = 'operator_' + i;
                        const operators = ['==', '!=', '<', '>', '>=', '<='];
                        return (
                            <tr>
                                <th style={{ backgroundColor: 'rgb(243, 243, 243)', border }}>{i + 1}</th>
                                {state.inputExpressions.map((ie) => {
                                    return (
                                        <>
                                            <td style={{ border }}>
                                                <label className="casetivity-off-screen" htmlFor={operatorHtmlID}>
                                                    Operator
                                                </label>
                                                <select
                                                    value={r[ie.id + '_operator'] as string}
                                                    onChange={(e) => {
                                                        setState((state) => {
                                                            return produce(state, (draft) => {
                                                                draft.rules[i][ie.id + '_operator'] = e.target.value;
                                                            });
                                                        });
                                                    }}
                                                    id={operatorHtmlID}
                                                >
                                                    {operators.map((op) => (
                                                        <option key={op} value={op}>
                                                            {op}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                            <td style={{ border }}>
                                                {ie.entries ? (
                                                    <select
                                                        style={{ width: '100%' }}
                                                        value={r[ie.id + '_expression'] as string}
                                                        onChange={(e) => {
                                                            setState((state) => {
                                                                return produce(state, (draft) => {
                                                                    draft.rules[i][ie.id + '_expression'] =
                                                                        e.target.value;
                                                                });
                                                            });
                                                        }}
                                                        aria-label={'Expression'}
                                                    >
                                                        {ie.entries.map((e: string) => (
                                                            <option key={e} value={e}>
                                                                {e}
                                                            </option>
                                                        ))}
                                                    </select>
                                                ) : (
                                                    <input
                                                        style={{ width: '100%' }}
                                                        type="text"
                                                        value={(r[ie.id + '_expression'] ?? '') as string}
                                                        onChange={(e) => {
                                                            setState((state) => {
                                                                return produce(state, (draft) => {
                                                                    draft.rules[i][ie.id + '_expression'] =
                                                                        e.target.value;
                                                                });
                                                            });
                                                        }}
                                                    />
                                                )}
                                            </td>
                                        </>
                                    );
                                })}
                                {state.outputExpressions.map((oe) => {
                                    const value = (r[oe.id] ?? '') as string;
                                    return (
                                        <td key={oe.id} style={{ border }}>
                                            {oe.entries ? (
                                                <select
                                                    style={{ width: '100%' }}
                                                    value={value}
                                                    onChange={(e) => {
                                                        setState((state) => {
                                                            return produce(state, (draft) => {
                                                                draft.rules[i][oe.id] = e.target.value;
                                                            });
                                                        });
                                                    }}
                                                    aria-label={'Output Expression'}
                                                >
                                                    {oe.entries.map((e: string) => (
                                                        <option key={e} value={e}>
                                                            {e}
                                                        </option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <input
                                                    style={{ width: '100%' }}
                                                    type="text"
                                                    value={value}
                                                    onChange={(e) => {
                                                        setState((state) => {
                                                            return produce(state, (draft) => {
                                                                draft.rules[i][oe.id] = e.target.value;
                                                            });
                                                        });
                                                    }}
                                                />
                                            )}
                                        </td>
                                    );
                                })}
                                <td>
                                    <div>
                                        {i !== 0 && (
                                            <IconButton size="small">
                                                <MoveUp
                                                    onClick={() => {
                                                        setState((state) => {
                                                            return produce(state, (draft) => {
                                                                const us = draft.rules[i];
                                                                const them = draft.rules[i - 1];
                                                                draft.rules[i] = them;
                                                                draft.rules[i - 1] = us;
                                                            });
                                                        });
                                                    }}
                                                    fontSize="small"
                                                />
                                            </IconButton>
                                        )}
                                        {i !== arr.length - 1 && (
                                            <IconButton
                                                onClick={() => {
                                                    setState((state) => {
                                                        return produce(state, (draft) => {
                                                            const us = draft.rules[i];
                                                            const them = draft.rules[i + 1];
                                                            draft.rules[i] = them;
                                                            draft.rules[i + 1] = us;
                                                        });
                                                    });
                                                }}
                                                size="small"
                                            >
                                                <MoveDown fontSize="small" />
                                            </IconButton>
                                        )}
                                        <IconButton size="small">
                                            <Delete
                                                onClick={() => {
                                                    setState((state) => {
                                                        return produce(state, (draft) => {
                                                            draft.rules.splice(i, 1);
                                                        });
                                                    });
                                                }}
                                                color="error"
                                                fontSize="small"
                                            />
                                        </IconButton>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: theme.spacing(1),
                    marginLeft: theme.spacing(1),
                }}
            >
                <Button
                    onClick={() =>
                        setState((state) => {
                            return produce(state, (draft) => {
                                draft.rules.push({});
                            });
                        })
                    }
                    variant="contained"
                    color="primary"
                    size="small"
                    endIcon={<Add fontSize="small" />}
                >
                    Add Rule
                </Button>
            </div>
        </div>
    );
};
export default ControlledDecisionTableEditor;
