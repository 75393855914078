import buildHeaders from 'sideEffect/buildHeaders';
import useRemoteData from 'util/hooks/useRemoteData';

const config = require('../../../../../config.js');

export interface ApiAddress {
    casetivityCity: string;
    casetivityCityCode: string;
    casetivityCounty: string;
    casetivityState: string;
    casetivityStateCode: string;
    casetivityStreet: string;
    casetivityUnit: string;
    casetivityZip: string;
    casetivityZipPlus: string;
    latitude: number;
    longitude: number;
    oneLiner: string;
}
export interface Suggestion extends ApiAddress {
    addressValidationDate: string;
    latitude: number;
    longitude: number;
    censusBlock: string;
    censusKey: string;
    censusTract: string;
    addressKey?: string;
    melissaAddressKey?: string;
    matchFound: 'Match found' | 'Match not found';
    resultCodes: string[];
}

export type Response = {
    current: ApiAddress;
    recommendations: [Suggestion];
    results: string[];
};

const useGisLookup = ({
    line1,
    line2,
    city,
    zip,
    state,
}: {
    line1: string;
    line2: string;
    city: string;
    zip: string;
    state: string;
}) => {
    const remoteData = useRemoteData<Response, string>();
    const verify = async () => {
        remoteData.setPending();
        fetch(`${config.API_URL}verify`, {
            method: 'POST',
            credentials: 'include',
            headers: buildHeaders({
                includeCredentials: true,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
                casetivityStreet: line1,
                casetivityUnit: line2,
                casetivityCity: city,
                casetivityZip: zip,
                casetivityState: state,
            }),
        })
            .then((r) => r.json())
            .then((r: Response) => {
                remoteData.setSuccess(r);
            })
            .catch((e) => {
                console.error(e);
                remoteData.setError(e.message);
            });
    };
    return [remoteData, verify] as [typeof remoteData, typeof verify];
};
export default useGisLookup;
