import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Address2Props } from './types';
import getImpl from 'expressions/Provider/implementations/getImpl';
import useValueSets from 'util/hooks/useValueSets';
import useConcepts from 'util/hooks/useConcepts';
import { change } from 'redux-form';
import {
    Button,
    Collapse,
    Divider,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert/Alert';
import isEqualWith from 'lodash/isEqualWith';
import useExpressionTesterOpen from 'expression-tester/hooks/useExpressionTesterOpen';
import { useAppSelector, useAppStore } from 'reducers/rootReducer';
import useGisLookup, { Response } from './api/gisLookup';
import {
    useEvaluateExpressionInFormContext,
    useEvaluatorInFormContext,
} from 'expressions/hooks/allForms/useEvaluateExpression';
import { useFFContext } from '../bpmForm/internal/ControlledForm';
import useGetMaybeValuesetFromField from './hooks/useGetMaybeValuesetFromField';
import useGetFieldSource from './hooks/useGetFieldSource';
import { useRelevantFormContext } from 'expressions/hooks/allForms/useRelevantFormContext';
import isPlainObject from 'lodash/isPlainObject';
import useUserIsSuper from 'util/hooks/useUserIsSuper';

/**
 *
 * isEqual, but treat '', and null the same
 */
const valuesEqual = (left: Record<string, unknown>, right: Record<string, unknown>): boolean => {
    return isEqualWith(left, right, (l, r) => {
        if (l === '' && r === null) {
            return true;
        }
        if (r === '' && l === null) {
            return true;
        }
        return undefined;
    });
};

type MaybeGetFieldValueset = (field: string) => string | null;

const useFieldExpressionAttributes = (field: string, maybeGetFieldValueset: MaybeGetFieldValueset) => {
    const maybeValueSet = maybeGetFieldValueset(field);
    const valueSets = useValueSets();
    const concepts = useConcepts();
    const valueSet = maybeValueSet && valueSets[maybeValueSet];
    const lookupTable = useMemo(() => {
        const dict: {
            [code: string]: string; // id
        } = {};
        valueSet?.conceptIds?.forEach((id) => {
            const code = concepts[id]?.code;
            if (!code) {
                console.error(`code not found in valueset ${maybeValueSet} concept id ${id}`);
            } else {
                dict[code] = id;
            }
        });
        return dict;
    }, [valueSet, concepts, maybeValueSet]);
    return {
        maybeValueSet,
        lookupTable,
    };
};

/**
 *
 * // basically duplicated in orginal. Only different in the 'dispatchValue' stuff if it's a valueset.
 *
 * @param expression SPEL expression containing at max, a single field (which we will update)
 * @param maybeGetFieldValueset returns a valueset string, if we need to look up the id for a concept to update the field in the expression
 */
const useGetUpdateField = (
    expression: string,
    maybeGetFieldValueset: MaybeGetFieldValueset,
    dispatchValues: (values: Record<string, string>) => void,
) => {
    const getFieldSource = useGetFieldSource();
    const compiledExpression = useMemo(() => getImpl().compileExpression(expression), [expression]);
    const fields = compiledExpression.type === 'parse_failure' ? [] : compiledExpression.getPathsWithAll();
    if (fields.length === 0) {
        // no fields found
    }
    const [field] = fields;

    const { lookupTable, maybeValueSet } = useFieldExpressionAttributes(field, maybeGetFieldValueset);
    const fieldSource = getFieldSource(field);

    const updateField = (codeOrText: string) => {
        if (!maybeValueSet) {
            dispatchValues({
                [field]: codeOrText,
            });
            return;
        }
        const id = lookupTable[codeOrText];
        if (!id) {
            // throw? not sure.
            console.error(
                `A concept by code '${codeOrText}' could not be looked up for valueSet '${maybeValueSet}'. Table logged below`,
            );
            console.log(lookupTable);
            return;
        }

        dispatchValues({
            [`${fieldSource}Id`]: id,
            [`${fieldSource}Code`]: codeOrText,
        });
    };
    return Object.assign(updateField, { fieldSource });
};

const useGetUpdateVsManyField = (
    field: string,
    maybeGetFieldValueset: MaybeGetFieldValueset,
    dispatchValue: (field: string, value: string[]) => void,
) => {
    const getFieldSource = useGetFieldSource();
    const { lookupTable, maybeValueSet } = useFieldExpressionAttributes(field, maybeGetFieldValueset);

    return useCallback(
        (codes: null | string[]) => {
            if (!field) {
                return;
            }
            if (!maybeValueSet) {
                console.error(`Valueset couldn't be looked up for field "${field}" in field "${field}"`);
                return;
            }
            const fieldSource = getFieldSource(field);
            if (!codes || !Array.isArray(codes)) {
                dispatchValue(`${fieldSource}Ids`, null);
                dispatchValue(`${fieldSource}Codes`, null);
                return;
            }
            const ids = codes.map((code) => lookupTable[code]);
            if (ids.some((id) => !id)) {
                // throw? not sure.
                console.error(
                    `At least one code in 'resultCodes' couldn't be looked up:\n codes: ${JSON.stringify(
                        codes,
                    )}\n ids:${JSON.stringify(ids)}`,
                );
                return;
            }

            dispatchValue(`${fieldSource}Ids`, ids);
            dispatchValue(`${fieldSource}Codes`, codes);
        },
        [dispatchValue, lookupTable, field, maybeValueSet, getFieldSource],
    );
};

const useUpdateWriteOnlyField = (
    key: keyof Address2Props['writeOnlyFields'],
    writeOnlyFields: Address2Props['writeOnlyFields'],
    dispatchChange: (field: string, value: unknown) => void,
) => {
    const field = writeOnlyFields[key];
    return useCallback(
        (value: unknown) => {
            if (!field) {
                return;
            }
            dispatchChange(field, value);
        },
        [field, dispatchChange],
    );
};

const useStyles = makeStyles((theme) => ({
    error: {
        color: theme.palette.error.main,
    },
}));

// updateCounty.fieldSource
const useWarnInvalidCounty = (
    fieldSource: string,
    acceptedValuesRef: React.MutableRefObject<{
        line1: string;
        line2: string;
        city: string;
        county: string;
        state: string;
        zip: string;
    }>,
    currentValues: {
        line1;
        line2;
        city;
        state;
        zip;
        county;
    },
) => {
    const userIsSuper = useUserIsSuper();
    const { line1, line2, city, state, zip, county } = currentValues;
    const fc = useRelevantFormContext();
    const checkInvalidConceptsOnNextUpdateRef = useRef(false);

    const availableCountiesRef = useRef(fc.valuesetFieldAvailableConceptIds?.[fieldSource]);
    availableCountiesRef.current = fc.valuesetFieldAvailableConceptIds?.[fieldSource];

    const countyIdRef = useRef<string>();
    countyIdRef.current = fc.fieldValues[fieldSource + 'Id'];

    const check = () => {
        const acceptedExceptCounty = Object.fromEntries(
            Object.entries(acceptedValuesRef.current).filter(([k]) => k !== 'county'),
        );
        const isInvalidUpdatedCounty =
            checkInvalidConceptsOnNextUpdateRef.current &&
            acceptedValuesRef.current.county &&
            valuesEqual(acceptedExceptCounty, {
                line1,
                line2,
                city,
                state,
                zip,
            }) &&
            isPlainObject(availableCountiesRef.current) &&
            !availableCountiesRef.current[countyIdRef.current];
        if (isInvalidUpdatedCounty) {
            if (userIsSuper) {
                alert(
                    `The selected county (${acceptedValuesRef.current.county}) is not allowed under the current concept expressions.
                    To fix this, change the concept expression on county to allow all concepts when verified. e.g.
                    verificationStatusCode == "FOUND_ADDRESS" ? "*" : <<<REST OF EXPRESSION>>>`,
                );
            }
        }
        checkInvalidConceptsOnNextUpdateRef.current = false;
        return isInvalidUpdatedCounty;
    };
    return {
        check,
        checkInvalidConceptsOnNextUpdateRef,
    };
};

const CrossFormAddressWidget = (props: Address2Props) => {
    const {
        input: { onBlur },
    } = props;
    const classes = useStyles();
    const store = useAppStore();

    const options = {
        defaultOnException: null,
    } as const;

    const line1 = useEvaluateExpressionInFormContext(props.addressExpressions.line1, options);
    const evalLine1 = useEvaluatorInFormContext(props.addressExpressions.line1, options);
    const line2 = useEvaluateExpressionInFormContext(props.addressExpressions.line2, options);
    const evalLine2 = useEvaluatorInFormContext(props.addressExpressions.line2, options);
    const city = useEvaluateExpressionInFormContext(props.addressExpressions.city, options);
    const evalCity = useEvaluatorInFormContext(props.addressExpressions.city, options);
    const county = useEvaluateExpressionInFormContext(props.addressExpressions.county, options);
    const evalCounty = useEvaluatorInFormContext(props.addressExpressions.county, options);
    const state = useEvaluateExpressionInFormContext(props.addressExpressions.state, options);
    const evalState = useEvaluatorInFormContext(props.addressExpressions.state, options);
    const zip = useEvaluateExpressionInFormContext(props.addressExpressions.zip, options);
    const evalZip = useEvaluatorInFormContext(props.addressExpressions.zip, options);

    const acceptedValuesRef = useRef<{
        line1: string;
        line2: string;
        city: string;
        county: string;
        state: string;
        zip: string;
    }>(null);
    const isLoading = useAppSelector((state) => !!state.admin.loading);
    /**
     * Load the accepted values only if and when 'loading' has finished. That ensures expressions like 'stateCode' have the values we want, and so we don't dispatch 'NO_MATCH' when that valueset loads, marking our form dirty.
     */
    useEffect(() => {
        if (!isLoading && !acceptedValuesRef.current) {
            acceptedValuesRef.current = {
                line1,
                line2,
                city,
                county,
                state,
                zip,
            };
        }
    }, [isLoading, line1, line2, city, county, state, zip]);

    const getMaybeValuesetFromField = useGetMaybeValuesetFromField();

    const ffContext = useFFContext();
    const dispatchChange = useCallback(
        (field, value) => {
            const reduxFormId = typeof props.meta.form === 'string' ? props.meta.form : null;
            if (reduxFormId) {
                store.dispatch(change(props.meta.form, field, value));
                return;
            }
            if (ffContext) {
                ffContext.form.mutators.changeField(field, value);
                return;
            }
            console.error(`Failed to find a form context to update field "${field}"`);
        },
        [store, props.meta.form, ffContext],
    );

    const updateLine1 = useGetUpdateField(props.addressExpressions.line1, getMaybeValuesetFromField, (values) => {
        acceptedValuesRef.current.line1 = evalLine1(values);
        Object.entries(values).forEach(([field, value]) => {
            dispatchChange(field, value);
        });
    });
    const updateLine2 = useGetUpdateField(props.addressExpressions.line2, getMaybeValuesetFromField, (values) => {
        acceptedValuesRef.current.line2 = evalLine2(values);
        Object.entries(values).forEach(([field, value]) => {
            dispatchChange(field, value);
        });
    });
    const updateCity = useGetUpdateField(props.addressExpressions.city, getMaybeValuesetFromField, (values) => {
        acceptedValuesRef.current.city = evalCity(values);
        Object.entries(values).forEach(([field, value]) => {
            dispatchChange(field, value);
        });
    });

    const updateCounty = useGetUpdateField(props.addressExpressions.county, getMaybeValuesetFromField, (values) => {
        acceptedValuesRef.current.county = evalCounty(values);
        Object.entries(values).forEach(([field, value]) => {
            dispatchChange(field, value);
        });
    });
    const updateState = useGetUpdateField(props.addressExpressions.state, getMaybeValuesetFromField, (values) => {
        acceptedValuesRef.current.state = evalState(values);
        Object.entries(values).forEach(([field, value]) => {
            dispatchChange(field, value);
        });
    });
    const updateZip = useGetUpdateField(props.addressExpressions.zip, getMaybeValuesetFromField, (values) => {
        acceptedValuesRef.current.zip = evalZip(values);
        Object.entries(values).forEach(([field, value]) => {
            dispatchChange(field, value);
        });
    });

    // now writeOnlyFields
    const updateZipPlus = useUpdateWriteOnlyField('zipPlus', props.writeOnlyFields, dispatchChange);
    const updateLongitude = useUpdateWriteOnlyField('longitude', props.writeOnlyFields, dispatchChange);
    const updateLatitude = useUpdateWriteOnlyField('latitude', props.writeOnlyFields, dispatchChange);
    const updateCensusBlock = useUpdateWriteOnlyField('censusBlock', props.writeOnlyFields, dispatchChange);
    const updateCensusKey = useUpdateWriteOnlyField('censusKey', props.writeOnlyFields, dispatchChange);
    const updateCensusTract = useUpdateWriteOnlyField('censusTract', props.writeOnlyFields, dispatchChange);
    const updateMelissaAddressKey = useUpdateWriteOnlyField('melissaAddressKey', props.writeOnlyFields, dispatchChange);
    const updateAddressValidationDate = useUpdateWriteOnlyField(
        'addressValidationDate',
        props.writeOnlyFields,
        dispatchChange,
    );
    const updateResultCodes = useGetUpdateVsManyField(
        props.writeOnlyFields.resultCodes,
        getMaybeValuesetFromField,
        dispatchChange,
    );

    const [{ fold: gisLookupFold, setInitial: setGisLookupInitial }, tryIt] = useGisLookup({
        line1,
        line2,
        city,
        zip,
        state,
    });

    const clearWriteOnlyFields = useCallback(() => {
        updateZipPlus(null);
        updateLongitude(null);
        updateLatitude(null);
        updateCensusBlock(null);
        updateCensusKey(null);
        updateCensusTract(null);
        updateMelissaAddressKey(null);
        updateAddressValidationDate(null);
        updateResultCodes(null);
    }, [
        updateZipPlus,
        updateLongitude,
        updateLatitude,
        updateCensusBlock,
        updateCensusKey,
        updateCensusTract,
        updateMelissaAddressKey,
        updateAddressValidationDate,
        updateResultCodes,
    ]);

    const { check, checkInvalidConceptsOnNextUpdateRef } = useWarnInvalidCounty(
        updateCounty.fieldSource,
        acceptedValuesRef,
        {
            line1,
            line2,
            city,
            state,
            zip,
            county,
        },
    );
    useEffect(() => {
        if (
            acceptedValuesRef.current &&
            !valuesEqual(acceptedValuesRef.current, {
                line1,
                line2,
                city,
                county,
                state,
                zip,
            })
        ) {
            const onlyInvalidCounty = check();
            if (onlyInvalidCounty) {
                return;
            }

            onBlur('NO_MATCH');
            clearWriteOnlyFields();
        }
        checkInvalidConceptsOnNextUpdateRef.current = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [line1, line2, city, county, state, zip, clearWriteOnlyFields]);

    const expressionTesterOpen = useExpressionTesterOpen() === 'OPEN_ALL';

    const setVerified = useCallback(() => {
        setGisLookupInitial();
        onBlur('FOUND_ADDRESS');
    }, [onBlur, setGisLookupInitial]);

    const continueNoMatch = useCallback(() => {
        setGisLookupInitial();
        acceptedValuesRef.current = {
            line1,
            line2,
            county,
            state,
            city,
            zip,
        };
        onBlur('MANUAL_OVERRIDE');
        clearWriteOnlyFields();
    }, [setGisLookupInitial, onBlur, line1, line2, county, state, city, zip, clearWriteOnlyFields]);
    const renderData = (response: Response) => {
        return (
            <Collapse mountOnEnter={true} appear={true} unmountOnExit={true} in={true}>
                {response.recommendations.length > 0 ? <Divider style={{ marginTop: '.5em' }} /> : null}
                {(() => {
                    const [recc] = response.recommendations;
                    if (!recc || recc.matchFound === 'Match not found') {
                        return (
                            <>
                                <Alert
                                    severity="warning"
                                    action={
                                        <Button onClick={continueNoMatch} size="small" variant="contained">
                                            Continue (no match)
                                        </Button>
                                    }
                                >
                                    Match not found
                                </Alert>
                            </>
                        );
                    }
                    return (
                        <List style={{ overflow: 'auto', maxHeight: 300 }}>
                            {response.recommendations.map((s, i) => {
                                return (
                                    <ListItem role="listitem" key={i}>
                                        <ListItemText primary={s.oneLiner} />

                                        <Button
                                            size="small"
                                            onClick={() => {
                                                checkInvalidConceptsOnNextUpdateRef.current = true;
                                                if (props.addressExpressions.line2?.trim()) {
                                                    // if we have a line2-type field configured
                                                    updateLine1(s.casetivityStreet);
                                                    updateLine2(s.casetivityUnit);
                                                } else {
                                                    updateLine1(
                                                        [s.casetivityStreet, s.casetivityUnit]
                                                            .map((s) => s?.trim())
                                                            .filter(Boolean)
                                                            .join(', '),
                                                    );
                                                }

                                                updateCity(s.casetivityCity);
                                                updateCounty(s.casetivityCounty);
                                                updateState(s.casetivityState);
                                                updateZip(s.casetivityZip);

                                                updateZipPlus(s.casetivityZipPlus);
                                                updateLongitude(s.longitude);
                                                updateLatitude(s.latitude);
                                                updateCensusBlock(s.censusBlock);
                                                updateCensusKey(s.censusKey);
                                                updateCensusTract(s.censusTract);
                                                updateMelissaAddressKey(s.addressKey || s.melissaAddressKey);
                                                updateAddressValidationDate(s.addressValidationDate);
                                                updateResultCodes(s.resultCodes);

                                                setVerified();
                                            }}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Select
                                        </Button>
                                    </ListItem>
                                );
                            })}
                            <ListItem role="listitem">
                                <ListItemText primary="" />
                                <ListItemSecondaryAction>
                                    <Button onClick={continueNoMatch} variant="contained" size="small">
                                        Continue (no match)
                                    </Button>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    );
                })()}
            </Collapse>
        );
    };
    return (
        <div>
            <Button
                disabled={isLoading && !acceptedValuesRef.current} // disable until our valuesets are loaded.
                variant="contained"
                color="primary"
                onClick={tryIt}
            >
                {props.label || 'Verify Address'}
            </Button>
            {gisLookupFold(
                () => null,
                (prev) => (prev ? renderData(prev) : null),
                renderData,
                (error) => (
                    <div>Error. {error}</div>
                ),
            )}
            {props.meta.error && (
                <span className={classes.error} style={{ marginTop: '1em', display: 'inline-block' }}>
                    {props.meta.error}
                </span>
            )}
            {expressionTesterOpen && (
                <>
                    <p>value: {props.input.value}</p>
                    <pre>
                        {JSON.stringify(
                            {
                                line1,
                                line2,
                                city,
                                county,
                                state,
                                zip,
                            },
                            null,
                            1,
                        )}
                    </pre>
                </>
            )}
        </div>
    );
};

export default CrossFormAddressWidget;
