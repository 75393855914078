import * as React from 'react';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { RootState, useAppSelector } from '../../reducers/rootReducer';
import DeferredSpinner from 'components/DeferredSpinner';
import PrivateGlobalAlerts from 'global-alerts/private/components/PrivateGlobalAlerts';
import ImpersonateBanner from 'impersonate/components/ImpersonateBanner';
import ConnectedIdleTimer from 'idle-timer/containers/ConnectedIdleTimer';
import CheckLastRequestRunner from 'reauth-interval-and-retries/check-last-request/components/CheckLastRequestRunner';
import OfflineAlert from 'reauth-interval-and-retries/check-last-request/components/OfflineAlert';
import DebugActionDispatcher from 'debug-action-dispatcher';
import AppFooter from 'footer';
import PreviewBanner from 'layout-editor/preview/PreviewBanner';
import useViewConfig from 'util/hooks/useViewConfig';
import useIsAnonymous from 'util/hooks/useIsAnonymous';
import useRootTheme from './hooks/useRootTheme';
import useSetupOnMount from './hooks/useAppSetupOnMount';
import AutoSavingBanner from 'bpm/components/TaskDetail/TaskForm/TaskForm/AutoSave/Banner';
import IEBannerComponent from './IEAlert';
import ViewConfigNeedsRefresh from './ViewConfigNeedsRefresh/ViewConfigNeedsRefresh';
import { CssBaseline } from '@material-ui/core';
import CurrentProfileBanner from 'auth/profiles/components/CurrentProfileBanner';
import SomeOfflineTasksBanner from 'offline_app/offline_stateful_tasks/back_online/components/AnyOfflineWorkBanner';
import isOffline from 'util/isOffline';
import GoBackOnlineBanner from 'offline_app/offline_stateful_tasks/GoBackOnlineBanner';
import isTheInstalledApp from 'util/isTheInstalledApp';
import SwitchToInstalledAppBanner from 'offline_app/offline_stateful_tasks/back_online/components/UseInstalledAppBanner';
import LoadOfflineTasksFromIDB from 'offline_app/offline_stateful_tasks/components/LoadOfflineTasksFromIDB';
import useIsTheInstalledApp from 'util/hooks/useIsTheInstalledApp';
import MinimalUIToolbarForStandaloneApp from './MinimalUIToolbarForStandaloneApp';
import Banner from './FixedPositionBanners';
import IssuesAlertBanner from 'temporary_registry/IssuesAlert';
import PrintSpinner from 'print/components/Spinner';
import { createTheme as createThemeV4 } from '@material-ui/core/styles';
import { createTheme as createThemeV5 } from '@mui/material/styles';
import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles';
import { generateClassName } from './generateClassName';
import useRootThemeV5 from './hooks/useRootThemeV5';

export const ThemeRoot: React.FC<{}> = (props) => {
    const themeV4 = useRootTheme(createThemeV4);
    const themeV5 = useRootThemeV5(createThemeV5);
    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProviderV5 theme={themeV5}>
                <MuiThemeProvider theme={themeV4}>
                    <CssBaseline />
                    {props.children}
                </MuiThemeProvider>
            </ThemeProviderV5>
        </StylesProvider>
    );
};

interface LayoutRootProps {}
const LayoutRoot: React.FunctionComponent<LayoutRootProps> = (props) => {
    useSetupOnMount();
    const anonUser = useIsAnonymous();
    const viewConfig = useViewConfig();
    const viewConfigIsLoading = useAppSelector((state: RootState) => state.viewConfigIsLoading);

    // when we log in, viewConfigIsLoading is false
    // when it resets to true, let our synchronous middleware run (calculating things like defaultValueExpressions, visibilityExpressions, etc.)
    // so those are all calculated before our views mount and begin fetching the expansions they need
    const [show, setShow] = React.useState(!viewConfigIsLoading);
    React.useEffect(() => {
        if (!viewConfigIsLoading && !show) {
            const to = setImmediate(() => setShow(true));
            return () => clearImmediate(to);
        }
    }, [viewConfigIsLoading, show]);
    const isInstalledApp = useIsTheInstalledApp();
    const printMode = useAppSelector((state: RootState) => state.printMode);
    if (!viewConfig || (Object.keys(viewConfig).length === 0 && viewConfigIsLoading) || !show) {
        return <DeferredSpinner />;
    }
    return (
        <React.Fragment key={isInstalledApp ? 'app' : 'browser'}>
            {!anonUser && <CheckLastRequestRunner />}
            {!anonUser && !printMode && <OfflineAlert />}
            <LoadOfflineTasksFromIDB />
            <ConnectedIdleTimer>
                <div
                    style={{
                        display: printMode ? undefined : 'table',
                        tableLayout: printMode ? undefined : 'fixed',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            display: printMode ? undefined : 'table-row',
                            /* height is dynamic, and will expand... */
                            height: '100%',
                            /* ...as content is added (won't scroll) */
                        }}
                    >
                        <MinimalUIToolbarForStandaloneApp />
                        {!printMode && (
                            <>
                                <Banner />
                                <IssuesAlertBanner />
                                <CurrentProfileBanner />
                                <ImpersonateBanner />
                                <AutoSavingBanner />
                                <IEBannerComponent />
                                <PrivateGlobalAlerts />
                                <PreviewBanner />
                                {!isTheInstalledApp() ? (
                                    <SwitchToInstalledAppBanner />
                                ) : isOffline() ? (
                                    <GoBackOnlineBanner />
                                ) : (
                                    <SomeOfflineTasksBanner />
                                )}
                            </>
                        )}
                        <ViewConfigNeedsRefresh>{props.children}</ViewConfigNeedsRefresh>
                        <PrintSpinner />
                        <DebugActionDispatcher />
                    </div>
                    {!printMode && (
                        <div
                            style={{
                                display: 'table-row',
                            }}
                        >
                            <AppFooter />
                        </div>
                    )}
                </div>
            </ConnectedIdleTimer>
        </React.Fragment>
    );
};
export default LayoutRoot;
