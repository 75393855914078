import * as React from 'react';
import { useData } from 'fieldFactory/offline/XMany';
import useViewConfig from 'util/hooks/useViewConfig';
import Table from './CustomDatagrid/CustomDatagrid';
import { getAccessLevelForEntityField } from 'components/generics/utils/viewConfigUtils';
import { Button, TableBody, TableRow } from '@material-ui/core';
import { Field } from 'redux-form';
import Add from '@material-ui/icons/Add';
import orderBy from 'lodash/orderBy';
import { v4 as uuidv4 } from 'uuid';
import {
    FieldProps,
    InlineDatagridCoreProps,
    NewRowExpressionCell,
    InlineDatagridFieldProps,
    InlineDatagridProps,
    InlineStableDatagridCore,
    useEditableRows,
    useFetchRowData,
} from './DatagridUtils';
export { useFetchRowData, NewRowExpressionCell };

const emptyObject = {};
function InlineDatagridCore({
    id,
    rootEntityType,
    resource,
    overrideViewName,
    referencedByField,
    input: { value, onChange },
    showCreate = true,
    handleSelectExistingRecord,
    disabled,
    meta,
    rows,
    accessLevel,
}: InlineDatagridCoreProps & FieldProps) {
    // adding sorting
    const [sort, setSort] = React.useState<[string, 'asc' | 'desc']>(null);

    // if we 'clear' a set of relationships by setting e.g. 'field': []
    // we still haven't removed the links back to this root record on those individual entities.
    // to make sure 'data' returns what's relevant, we should refetch anything which points to our root record,
    // to ensure that's still the case.

    const { renderExistingRowCells, renderNewRowForm, headers } = useEditableRows({
        id,
        rootEntityType,
        resource,
        overrideViewName,
        referencedByField,
    });
    const initialValue = React.useMemo(() => {
        if (Array.isArray(meta.initial)) {
            return meta.initial.filter((e) => !e['id']).map((e) => ({ ...e, _key: uuidv4() }));
        }
        return [];
    }, [meta.initial]);

    const [additionalRows, setAdditionalRows] = React.useState(initialValue);

    React.useEffect(() => {
        onChange([...rows.map(({ id }) => ({ id })), ...additionalRows.map(({ _key, ...rest }) => rest)]);
    }, [rows, additionalRows, onChange]);

    const submitFailed = meta?.submitFailed;
    const additionalRowElements = React.useMemo(() => {
        return additionalRows.map((r, i) => (
            <tr key={r._key}>
                {renderNewRowForm({
                    submitFailed,
                    initialRowValues: initialValue[i] ?? emptyObject,
                    rowValues: r,
                    onChangeRowValues: (r) => {
                        setAdditionalRows([
                            ...(i > 0 ? additionalRows.slice(0, i) : []),
                            r as { _key: string },
                            ...(i !== additionalRows.length - 1 ? additionalRows.slice(i + 1) : []),
                        ]);
                    },
                    handleDelete: () =>
                        setAdditionalRows([...additionalRows.slice(0, i), ...additionalRows.slice(i + 1)]),
                })}
            </tr>
        ));
    }, [additionalRows, renderNewRowForm, submitFailed, initialValue]);

    const sortedRows = React.useMemo(() => {
        if (!sort) {
            return rows;
        }
        const [field, dir] = sort;
        return orderBy(rows, [field], [dir]);
    }, [rows, sort]);

    const TableContent = (
        <TableBody>
            {sortedRows.map((r, i) => (
                <TableRow key={'existing -' + i}>
                    {renderExistingRowCells({
                        record: r,
                        handleSelectExistingRecord,
                    })}
                </TableRow>
            ))}
            {!disabled && additionalRowElements}
        </TableBody>
    );

    return (
        <div>
            <Table
                currentSort={sort}
                setSort={setSort}
                headers={headers}
                minCellWidth={120}
                tableContent={TableContent}
                renderBelow={({ updateInternalTableHeight }) => {
                    if (disabled || showCreate === false || accessLevel <= 2) {
                        return null;
                    }
                    return (
                        <Button
                            color="primary"
                            endIcon={<Add />}
                            onClick={() => {
                                setAdditionalRows([...additionalRows, { _key: uuidv4() }]);
                                setImmediate(() => updateInternalTableHeight());
                            }}
                        >
                            Add Row
                        </Button>
                    );
                }}
            />
        </div>
    );
}

function InlineDatagrid({
    id,
    source,
    rootEntityType,
    resource,
    filter,
    overrideViewName,
    referencedByField,
    input,
    showCreate,
    handleSelectExistingRecord,
    disabled,
    meta,
}: InlineDatagridFieldProps) {
    // adding sorting
    const [sort, setSort] = React.useState<[string, 'asc' | 'desc']>(null);

    const data = useData(rootEntityType, source, id, filter);
    const rows = React.useMemo(() => Object.values(data) as any[], [data]);
    // if we 'clear' a set of relationships by setting e.g. 'field': []
    // we still haven't removed the links back to this root record on those individual entities.
    // to make sure 'data' returns what's relevant, we should refetch anything which points to our root record,
    // to ensure that's still the case.
    useFetchRowData(rows, resource);
    const viewConfig = useViewConfig();
    const accessLevel = React.useMemo(
        () => getAccessLevelForEntityField(viewConfig, rootEntityType, source, 'TRAVERSE_PATH'),
        [viewConfig, rootEntityType, source],
    );

    return (
        <InlineDatagridCore
            accessLevel={accessLevel}
            rows={rows}
            id={id}
            rootEntityType={rootEntityType}
            resource={resource}
            overrideViewName={overrideViewName}
            referencedByField={referencedByField}
            input={input}
            showCreate={showCreate}
            handleSelectExistingRecord={handleSelectExistingRecord}
            disabled={disabled}
            meta={meta}
        />
    );
}

const renderField = (props) => {
    return <InlineDatagrid {...props} />;
};
const InlineDatagridField = (props: InlineDatagridProps) => {
    return <Field {...props} name={props.source} component={renderField} />;
};

// "Core" means source doesn't necessarily map onto data. Currently it's used for unpersisted fields (e.g. _field)
const CoreField = (props) => {
    const rows = React.useMemo(() => (props.meta.initial ?? [])?.filter(({ id }) => id), [props.meta.initial]);
    return <InlineDatagridCore {...props} rows={rows} />;
};
export const InlineDatagridCoreField = (props: InlineDatagridCoreProps & { source }) => {
    return (
        <Field
            {...props}
            name={props.source}
            component={props.unchangingOrderedItems ? InlineStableDatagridCore : CoreField}
        />
    );
};

export default InlineDatagridField;
