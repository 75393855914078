import React, { createContext, useContext, useMemo, useState } from 'react';
import { RenderListArguments } from './List';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Checkbox,
    IconButton,
    useTheme,
    IconButtonProps,
    makeStyles,
    createStyles,
} from '@material-ui/core';
import { adjustLinkedXToLinkedEntity } from '../utils/viewConfigUtils';
import OverriddenRedirectProvider from './WithOverriddenRedirects';
import Themed from 'components/Themed';
import WithViewConfig from '../WithViewConfig';
import FieldSubscriber from 'fieldFactory/display/experimental/FieldSubscriber';
import { ViewField } from 'reducers/ViewConfigType';
import get from 'lodash/get';
import set from 'lodash/set';
import moment from 'moment';
import clone from 'clone';
import { CenteredIcon, SortLabel } from './SortArrows';
import uniqueId from 'lodash/uniqueId';
import EditableTableRow from './EditableTableRow/EditableTableRow';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import EditableFields from './EditableTableRow/EditableTableFields';
import TabbableIconButton from './EditableTableRow/TabbableIconButton';
import { Classes } from './Classes';
import WithInlineEditableExpressions from './EditableTableRow/WithInlineEditableExpressions';
import { denormalizeEntitiesByPaths } from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/denormalizing/buildEntityMappingsFromPaths';
import useEntities from 'util/hooks/useEntities';
import { parseTemplateString } from 'viewConfigCalculations/util/parseTemplateString';
import useViewConfig from 'util/hooks/useViewConfig';
import { formContext } from '../form/EntityFormContext/Show';
import getAdhocVariablesContextSelector from '../form/EntityFormContext/util/getVariablesContextSelector';
import formTypeContext from '../form/formTypeContext';
import Edit from '@material-ui/icons/Edit';
import Go from '@material-ui/icons/Forward';
import Clear from '@material-ui/icons/HighlightOffTwoTone';
import Alert from '@material-ui/lab/Alert';
import WithErrorBoundary from '../fields/WithErrorBoundary';

const reactElemIsExpressionField = <Props extends { source?: string }>(field: React.ReactElement<Props>) => {
    return field.props.source?.startsWith('$');
};

const UniqueId: React.FC<{
    children: (props: { id: string }) => JSX.Element;
}> = ({ children }) => {
    const id = useMemo(() => uniqueId('list-caption-'), []);
    return children({ id });
};

const SelCount: React.FC<{ numSelected: number }> = ({ numSelected, children }) => (
    <div style={{ position: 'relative', display: 'inline-block' }}>
        {numSelected > 0 && (
            <div
                style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    color: 'rgba(0, 0, 0, 0.54)',
                    top: '-7px',
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    overflow: 'visible',
                    textAlign: 'center',
                }}
            >
                {numSelected}
            </div>
        )}
        {children}
    </div>
);
export const rowIdContext = React.createContext<number | string | null>(null);

const range = (n) => Array.from({ length: n }, (value, key) => key);

export type RenderListArgumentsWithBulkSelection = RenderListArguments & {
    onRowSelectBulk?: Required<RenderListArguments>['onRowSelect'];
    single?: boolean;
    isBulkSelectableRecord?: (data: {}) => boolean;
    renderAtRowEnd?: (r: RenderListArguments, record: { id: any }) => React.ReactElement<{}>[];
};

const hoverFocusableTooltipContext = createContext(false);
const HoverFocusableTooltip: React.FC<{ children: JSX.Element }> = ({ children }) => {
    const [hover, setHover] = useState(false);
    return (
        <hoverFocusableTooltipContext.Provider value={hover}>
            {/* <TabbableIconButton 
            iconButtonProps={{
                size: 'small',
                'aria-label': 'Edit'
            }}
            hovered={hover}
        /> */}
            {/* 
        TODO
        cloneElement with mouse props
        and
        provide hover context for icon, so it can be embedded inside the table cell. */}
            {React.cloneElement(children, {
                onMouseOver: () => setHover(true),
                onMouseOut: () => setHover(false),
            })}
        </hoverFocusableTooltipContext.Provider>
    );
};
const HoverFocusableIcon = () => {
    const hover = useContext(hoverFocusableTooltipContext);
    return (
        <TabbableIconButton
            iconButtonProps={{
                size: 'small',
                'aria-label': 'Edit',
            }}
            hovered={hover}
        />
    );
};

export const renderTableHead = (
    r: RenderListArgumentsWithBulkSelection,
    classes: Classes = {},
    styles: Styles = {},
    columnsToAppendAtEnd: number = 0,
    sorting: 'ENABLED' | 'DISABLED' = 'ENABLED',
    sortCaptionId?: string,
) => {
    const rowCount = r.ids.length;
    const numSelectedInCurrentPage = r.selectedData
        ? Object.keys(r.selectedData).filter((id) => r.ids.includes(id)).length
        : 0;
    const someSelectedInDifferentPage =
        r.selectedData && Object.entries(r.selectedData).some(([id, d]) => !r.ids.includes(id));
    const numSelected = r.selectedData ? Object.keys(r.selectedData).length : 0;
    const allRowsSelected =
        r.selectedData &&
        (() => {
            const eligableRows = r.ids.filter(
                (id) => !r.isBulkSelectableRecord || r.isBulkSelectableRecord(r.data[id]),
            );
            return eligableRows.length > 0 && !eligableRows.some((id) => !r.selectedData[id]);
        })();

    const onSelectAllClick = () => {
        if (r.onRowSelectBulk) {
            if (numSelected === 0) {
                r.onRowSelectBulk(
                    r.ids.flatMap((id) => {
                        const rec = r.data[id];
                        if (!r.isBulkSelectableRecord || r.isBulkSelectableRecord(rec)) {
                            return [rec];
                        }
                        return [];
                    }),
                    r.data,
                );
            } else {
                r.onRowSelectBulk([], r.data);
            }
        }
    };
    const addAllInPageToSelectionClick = () => {
        if (r.onRowSelectBulk) {
            r.onRowSelectBulk(
                [
                    ...Object.values(r.selectedData),
                    ...r.ids.flatMap((id) => {
                        if (r.selectedData[id]) {
                            return [];
                        }
                        const rec = r.data[id];
                        if (!r.isBulkSelectableRecord || r.isBulkSelectableRecord(rec)) {
                            return [rec];
                        }
                        return [];
                    }),
                ],
                {
                    ...r.data,
                    ...r.selectedData,
                },
            );
        }
    };
    const removeCurrentRowsFromSelection = () => {
        if (r.onRowSelectBulk) {
            r.onRowSelectBulk(
                Object.values(r.selectedData).filter((e) => !r.ids.includes(e.id)),
                {
                    ...r.data,
                    ...r.selectedData,
                },
            );
        }
    };
    const clearAllSelections = () => {
        if (r.onRowSelectBulk) {
            r.onRowSelectBulk([], r.data);
        }
    };
    const currentSortOrder =
        r.currentSort && r.currentSort.order ? (r.currentSort.order.toLowerCase() as 'asc' | 'desc') : undefined;
    const currentSortField = r.currentSort && r.currentSort.field;
    const createSortHandler = (property: string) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        r.setSort(property);
    };
    const arCurrPage = !r.single && someSelectedInDifferentPage && (
        <Checkbox
            indeterminate={numSelectedInCurrentPage > 0 && !allRowsSelected}
            checked={allRowsSelected}
            onChange={() => {
                if (numSelectedInCurrentPage === 0) {
                    addAllInPageToSelectionClick();
                } else {
                    removeCurrentRowsFromSelection();
                }
            }}
            inputProps={{
                'aria-label':
                    numSelectedInCurrentPage === 0
                        ? 'add results to existing selection'
                        : 'remove items on current page from existing selection',
            }}
        />
    );
    const addRemoveAllNoOtherPageData = !someSelectedInDifferentPage && (
        <Checkbox
            style={{ visibility: r.single ? 'hidden' : undefined }}
            indeterminate={numSelected > 0 && numSelectedInCurrentPage < rowCount}
            // change below to operate only on current page
            checked={allRowsSelected}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all' }}
        />
    );
    const clearAllSelectionsFromAllPages = !r.single && someSelectedInDifferentPage && (
        <SelCount numSelected={numSelected}>
            <Tooltip title={`Clear entire selection of ${numSelected}`}>
                <IconButton aria-label={`Clear entire selection of ${numSelected}`} onClick={clearAllSelections}>
                    <Clear />
                </IconButton>
            </Tooltip>
        </SelCount>
    );

    return (
        <TableHead>
            <TableRow>
                {r.onRowSelectBulk && (
                    <TableCell padding="checkbox" className={classes.headerCell}>
                        <div style={{ whiteSpace: 'nowrap' }}>
                            {arCurrPage}
                            {addRemoveAllNoOtherPageData}
                            {clearAllSelectionsFromAllPages}
                        </div>
                    </TableCell>
                )}
                {r.fields.map((field, i) => {
                    const needsLeftPadding = !r.onRowSelectBulk && i === 0;

                    const label = field.props.inlineEditable ? (
                        <span>
                            <CenteredIcon
                                outerStyle={{ paddingRight: 0 }}
                                innerStyle={{
                                    left: '-.5rem',
                                }}
                            >
                                <Edit
                                    style={{
                                        fontSize: '1rem',
                                        transform: 'scaleX(-1)',
                                        filter: 'FlipH',
                                    }}
                                />
                            </CenteredIcon>
                            &nbsp;{field.props.label}
                        </span>
                    ) : (
                        field.props.label
                    );
                    if (field.props.sortable === false || sorting === 'DISABLED' || reactElemIsExpressionField(field)) {
                        return (
                            <TableCell
                                className={classes.headerCell}
                                classes={{
                                    root: classes.cell,
                                }}
                                padding="none"
                                style={needsLeftPadding ? { paddingLeft: '1em', ...styles.cell } : styles.cell}
                                key={field.props.source}
                                align={field.props.cellAlign}
                            >
                                <span>
                                    <SortLabel label={label} sortable={false} />
                                </span>
                            </TableCell>
                        );
                    }
                    return (
                        <TableCell
                            className={classes.headerCell}
                            classes={{
                                root: classes.cell,
                            }}
                            padding="none"
                            style={needsLeftPadding ? { paddingLeft: '1em', ...styles.cell } : styles.cell}
                            key={field.props.source}
                            sortDirection={
                                r.currentSort && r.currentSort.field === field.props.source ? currentSortOrder : false
                            }
                            align={field.props.cellAlign}
                        >
                            <Tooltip title="Sort" enterDelay={300}>
                                <span>
                                    <SortLabel
                                        ButtonProps={{
                                            onClick: createSortHandler(field.props.source),
                                            'aria-describedby': sortCaptionId,
                                        }}
                                        label={label}
                                        active={currentSortField ? currentSortField === field.props.source : undefined}
                                        direction={currentSortOrder}
                                    />
                                </span>
                            </Tooltip>
                        </TableCell>
                    );
                })}
                {range(columnsToAppendAtEnd).map((i) => (
                    <Themed key={i}>
                        {({ theme }) => (
                            <TableCell
                                className={classes.headerCell}
                                classes={{
                                    root: classes.cell,
                                }}
                                padding="none"
                                key={`atend-${i}`}
                            >
                                <span className="casetivity-off-screen">
                                    {i === columnsToAppendAtEnd - 1 ? 'Select Row Action' : 'Action'}
                                </span>
                            </TableCell>
                        )}
                    </Themed>
                ))}
            </TableRow>
        </TableHead>
    );
};

const getRowEnds = (r: RenderListArgumentsWithBulkSelection, handleIndexSelected?: (i: number) => void) => {
    return r.ids.map((id, i) => {
        const record = r.data[id];
        const navButtonElem = r.renderOverrideNavButton?.(record) ?? (
            <TableCell key={`rowEnd nav ${id} ${i}`} padding="none">
                <WithViewConfig>
                    {({ viewConfig }) => {
                        const displayName = viewConfig.entities[r.resource].displayName || r.resource;
                        return (
                            <span className="casetivity-hide-printing">
                                <TabNavRowButton
                                    key="navButton"
                                    IconComponent={
                                        r.getSelectIconComponent
                                            ? r.getSelectIconComponent(r, record)
                                            : r.SelectIconComponent
                                    }
                                    iconButtonProps={{
                                        'aria-label': `Select ${displayName}${
                                            record?.title ? `, ${record.title}` : ''
                                        }`,
                                        onClick: handleIndexSelected
                                            ? (e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  handleIndexSelected(i);
                                              }
                                            : undefined,
                                    }}
                                />
                            </span>
                        );
                    }}
                </WithViewConfig>
            </TableCell>
        );

        if (r.renderAtRowEnd && record) {
            const rowEnd = r.renderAtRowEnd(r, record).map((elem, i) => (
                <TableCell key={`rowEnd ${i}`} padding="none">
                    {elem}
                </TableCell>
            ));
            if (r.navButtonBefore) {
                return [navButtonElem, ...rowEnd];
            }
            return [...rowEnd, navButtonElem];
        }
        return [navButtonElem];
    });
};

interface TabNavRowButtonProps {
    iconButtonProps: IconButtonProps;
    IconComponent?: React.ComponentType<{}>;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        hiddenButton: {
            opacity: 0,
            height: 0,
            width: 0,
            zIndex: -1000,
            '&:focus': {
                opacity: 1,
                height: 'unset',
                width: 'unset',
                zIndex: 1,
            },
        },
    }),
);

export const TabNavRowButton: React.ComponentType<TabNavRowButtonProps> = ({ iconButtonProps, IconComponent = Go }) => {
    const theme = useTheme();
    const classes = useStyles();

    const { rowNavButtonsHideUntilFocus } = useContext(themeOverrideContext);
    return (
        <IconButton className={rowNavButtonsHideUntilFocus ? classes.hiddenButton : ''} {...iconButtonProps}>
            <IconComponent style={{ color: theme.palette.primary.main }} />
        </IconButton>
    );
};

interface Styles {
    root?: React.CSSProperties;
    cell?: React.CSSProperties;
}

export const ExpressionCell: React.FC<{
    expression: string;
    rootEntityType: string;
    rootEntityId: string;
}> = ({ expression, rootEntityType, rootEntityId, children }) => {
    const entities = useEntities();
    const viewConfig = useViewConfig();
    const parsed = useMemo(
        () => parseTemplateString(expression, viewConfig, rootEntityType),
        [expression, viewConfig, rootEntityType],
    );
    const expandedContext = useMemo(
        () => denormalizeEntitiesByPaths(entities, parsed.dataPaths, viewConfig, rootEntityType, rootEntityId),
        [entities, parsed, viewConfig, rootEntityType, rootEntityId],
    );
    const adhocVariablesContext = useMemo(getAdhocVariablesContextSelector, []);
    return (
        <formTypeContext.Provider value="SHOW">
            {/* faking a Show view, so the Expression field works with the data we provide here */}
            <formContext.Provider
                value={{
                    fieldValues: expandedContext,
                    hiddenFields: {},
                    valuesetFieldAvailableConceptIds: {},
                    viewName: null,
                    adhocVariablesContext: adhocVariablesContext({}),
                    variables: {},
                }}
            >
                {children}
            </formContext.Provider>
        </formTypeContext.Provider>
    );
};

const getRenderer = (classes: Classes, styles: Styles) => (r: RenderListArgumentsWithBulkSelection) => {
    const isSelected = (id) => r.selectedData && r.selectedData[id];
    const renderFields = (id: string, onClick?: (source: string) => void, dense?: boolean) => {
        return r.fields.map((f, i) => {
            const isExpressionField = reactElemIsExpressionField(f);
            const needsLeftPadding = !r.onRowSelectBulk && i === 0;
            const source = !isExpressionField && adjustLinkedXToLinkedEntity(f.props.source);
            const record = r.data[id];
            const fieldProps = {
                record,
                resource: r.resource,
                source,
            };
            const inlineEditableField = !!f.props.inlineEditable;
            const CellChildren = isExpressionField ? (
                (() => {
                    let expression: string;
                    try {
                        expression = JSON.parse(f.props['data-originaldefinition']).config;
                    } catch (e) {
                        return <Alert severity="error">Failed to read 'config' from Expression field.</Alert>;
                    }
                    return (
                        <WithErrorBoundary>
                            <ExpressionCell expression={expression} rootEntityId={id} rootEntityType={r.resource}>
                                {f}
                            </ExpressionCell>
                        </WithErrorBoundary>
                    );
                })()
            ) : source ? (
                <FieldSubscriber
                    resource={r.resource}
                    source={source}
                    id={id}
                    record={record}
                    renderDisplayField={({ value }) => {
                        const directValue = get(record, source, undefined);
                        if (record?.['_needsSubmit'] && typeof directValue !== 'undefined' && directValue !== value) {
                            // we want to view our offline data here.
                            const copiedFieldProps = {
                                ...fieldProps,
                                record: (() => {
                                    const copiedRecord = clone(fieldProps.record);
                                    set(copiedRecord, source, directValue);
                                    return copiedRecord;
                                })(),
                            };
                            return React.cloneElement(f, copiedFieldProps);
                        }

                        if (value === null || typeof value === 'undefined') {
                            if (id && moment(id).isValid()) {
                                // if is pending create, value will fail to look up.
                                // we have a 'faked' record, so lets just get the value off of it directly
                                return React.cloneElement(f, fieldProps);
                            }
                            // if this is a file field, even though 'value' doesn't resolve,
                            // we still need to display our field element.
                            const fieldDefinition = f.props['data-originaldefinition'];
                            if (fieldDefinition) {
                                const definition = JSON.parse(fieldDefinition) as ViewField;
                                if (definition.widgetType === 'FILEUPLOAD') {
                                    return React.cloneElement(f, fieldProps);
                                }
                            }
                            return null;
                        }
                        return React.cloneElement(f, fieldProps);
                    }}
                />
            ) : (
                React.cloneElement(f, fieldProps)
            );

            const Cell = (
                <TableCell
                    onClick={
                        inlineEditableField
                            ? (e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  onClick?.(source);
                              }
                            : undefined
                    }
                    classes={{
                        root: r.classes?.cell ?? classes.cell,
                    }}
                    key={`${f.props.source}_${i}`}
                    padding="none"
                    style={{
                        ...(needsLeftPadding ? { paddingLeft: '1em', ...styles.cell } : styles.cell),
                        position: 'relative',
                        ...(inlineEditableField ? { cursor: 'pointer' } : {}),
                        height: dense ? '32px' : '48px', // height on tds works like min-height
                    }}
                    align={f.props.cellAlign}
                    // numeric here
                >
                    {/* When empty value, we need to make sure we don't render divs inside, for JAWS table navigation to work. */}
                    {/* VEA-1416 */}
                    {CellChildren}
                    {inlineEditableField ? <HoverFocusableIcon /> : null}
                </TableCell>
            );
            if (inlineEditableField) {
                return <HoverFocusableTooltip>{Cell}</HoverFocusableTooltip>;
            }

            return Cell;
        });
    };

    const { 'aria-label': label, ...tableAriaProps } = r.ariaProps as { 'aria-label'?: string };
    const containsSomeSort = r.fields?.some((f) => f.props.sortable !== false);
    return (
        <UniqueId>
            {({ id: sortCaptionId }) => (
                <OverriddenRedirectProvider
                    viewName={r.viewName}
                    onRowSelect={r.onRowSelect}
                    resource={r.resource}
                    ids={r.ids}
                    data={r.data}
                    render={(onIndexesSelected) => {
                        const rowEnds =
                            r.noClick && !r.keepRowEnds
                                ? r.ids.map((id, i) => [
                                      <TableCell key={`rowEnd spacer ${id} ${i}`} padding="none">
                                          <div style={{ height: 48 }} />
                                      </TableCell>,
                                  ])
                                : getRowEnds(r, r.noClick && r.keepRowEnds ? (i) => onIndexesSelected([i]) : undefined);
                        const extraColumnsNeeded = rowEnds.reduce(
                            (prev, curr) => (curr.length > prev ? curr.length : prev),
                            0,
                        );

                        return (
                            <div className={r.classes?.root ?? classes.root} style={styles.root}>
                                {r.resultHeadingText &&
                                    (typeof r.resultHeadingText === 'string' ? (
                                        <h2 style={{ marginTop: '0.5em', marginBottom: '0.5em' }}>
                                            {r.resultHeadingText}
                                        </h2>
                                    ) : (
                                        r.resultHeadingText
                                    ))}
                                <WithInlineEditableExpressions
                                    fields={r.fields as React.ReactElement<{ 'data-originaldefinition': string }>[]}
                                    entityType={r.resource}
                                >
                                    {({ editableExpressions }) => (
                                        <Table {...tableAriaProps}>
                                            <caption className="casetivity-off-screen">
                                                {label}
                                                {containsSomeSort && (
                                                    <span id={sortCaptionId}>, use column header buttons to sort</span>
                                                )}
                                            </caption>
                                            {renderTableHead(
                                                r,
                                                r.classes || classes,
                                                styles,
                                                extraColumnsNeeded,
                                                r.disableSorting ? 'DISABLED' : 'ENABLED',
                                                sortCaptionId,
                                            )}
                                            <TableBody>
                                                {r.ids.map((id, i) => {
                                                    return (
                                                        <themeOverrideContext.Consumer key={'toc' + id}>
                                                            {(ctxt) => (
                                                                <EditableTableRow key={id}>
                                                                    {({ openEdit, editing, focusField }) => (
                                                                        <>
                                                                            {editing ? (
                                                                                <themeOverrideContext.Provider
                                                                                    value={{
                                                                                        ...ctxt,
                                                                                        fieldVariant: 'standard',
                                                                                        forceLabelShrink: false,
                                                                                        getInputLabelProps: (props) =>
                                                                                            ctxt.getInputLabelProps({
                                                                                                ...props,
                                                                                                className:
                                                                                                    'casetivity-off-screen',
                                                                                            }),
                                                                                    }}
                                                                                >
                                                                                    <EditableFields
                                                                                        isChecked={!!isSelected(id)}
                                                                                        onRowSelectBulk={Boolean(
                                                                                            r.onRowSelectBulk,
                                                                                        )}
                                                                                        editableExpressions={
                                                                                            editableExpressions
                                                                                        }
                                                                                        autoFocusField={focusField}
                                                                                        onClose={() =>
                                                                                            openEdit({
                                                                                                focusField: null,
                                                                                            })
                                                                                        }
                                                                                        onSave={() => {
                                                                                            openEdit({
                                                                                                focusField: null,
                                                                                            });
                                                                                            r.refresh?.();
                                                                                        }}
                                                                                        record={r.data[id]}
                                                                                        resource={r.resource}
                                                                                        fields={r.fields}
                                                                                    />
                                                                                </themeOverrideContext.Provider>
                                                                            ) : (
                                                                                <TableRow
                                                                                    hover={!r.noClick}
                                                                                    onClick={
                                                                                        r.noClick
                                                                                            ? undefined
                                                                                            : (e) => {
                                                                                                  e.preventDefault();
                                                                                                  e.stopPropagation();
                                                                                                  if (
                                                                                                      // Let's check if selection is being made and if so let's not trigger click to allow for select and copy
                                                                                                      window
                                                                                                          .getSelection()
                                                                                                          .toString()
                                                                                                          .length
                                                                                                  ) {
                                                                                                      return;
                                                                                                  }
                                                                                                  onIndexesSelected([
                                                                                                      i,
                                                                                                  ]);
                                                                                              }
                                                                                    }
                                                                                    tabIndex={-1}
                                                                                    key={id}
                                                                                >
                                                                                    <rowIdContext.Provider value={id}>
                                                                                        {r.onRowSelectBulk && (
                                                                                            <TableCell padding="checkbox">
                                                                                                <Checkbox
                                                                                                    checked={
                                                                                                        !!isSelected(id)
                                                                                                    }
                                                                                                    disabled={
                                                                                                        r.isBulkSelectableRecord &&
                                                                                                        !r.isBulkSelectableRecord(
                                                                                                            r.data[id],
                                                                                                        )
                                                                                                    }
                                                                                                    onClick={(e) => {
                                                                                                        e.stopPropagation();
                                                                                                        e.preventDefault();
                                                                                                        if (
                                                                                                            r.selectedData &&
                                                                                                            r.onRowSelectBulk
                                                                                                        ) {
                                                                                                            const otherData =
                                                                                                                Object.entries(
                                                                                                                    r.selectedData,
                                                                                                                ).flatMap(
                                                                                                                    ([
                                                                                                                        sid,
                                                                                                                        sdata,
                                                                                                                    ]) =>
                                                                                                                        `${id}` !==
                                                                                                                        `${sid}`
                                                                                                                            ? [
                                                                                                                                  sdata,
                                                                                                                              ]
                                                                                                                            : [],
                                                                                                                );
                                                                                                            if (
                                                                                                                r
                                                                                                                    .selectedData[
                                                                                                                    id
                                                                                                                ]
                                                                                                            ) {
                                                                                                                r.onRowSelectBulk(
                                                                                                                    otherData,
                                                                                                                    r.data,
                                                                                                                );
                                                                                                            } else {
                                                                                                                if (
                                                                                                                    r.single
                                                                                                                ) {
                                                                                                                    r.onRowSelectBulk(
                                                                                                                        [
                                                                                                                            r
                                                                                                                                .data[
                                                                                                                                id
                                                                                                                            ],
                                                                                                                        ],
                                                                                                                        r.data,
                                                                                                                    );
                                                                                                                } else {
                                                                                                                    r.onRowSelectBulk(
                                                                                                                        [
                                                                                                                            ...otherData,
                                                                                                                            r
                                                                                                                                .data[
                                                                                                                                id
                                                                                                                            ],
                                                                                                                        ],
                                                                                                                        r.data,
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }}
                                                                                                    inputProps={{
                                                                                                        'aria-label':
                                                                                                            'select row',
                                                                                                    }}
                                                                                                />
                                                                                            </TableCell>
                                                                                        )}
                                                                                        {renderFields(
                                                                                            id,
                                                                                            (source) =>
                                                                                                openEdit({
                                                                                                    focusField: source,
                                                                                                }),
                                                                                            ctxt.denseUI,
                                                                                        )}
                                                                                        {rowEnds[i]}
                                                                                    </rowIdContext.Provider>
                                                                                </TableRow>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </EditableTableRow>
                                                            )}
                                                        </themeOverrideContext.Consumer>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    )}
                                </WithInlineEditableExpressions>
                            </div>
                        );
                    }}
                />
            )}
        </UniqueId>
    );
};

export default getRenderer;
