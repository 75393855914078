import React, { useLayoutEffect, useRef } from 'react';
import { TextField } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';
import DebouncedTextInput from './DebouncedTextInput';
import { activeTabContext } from 'components/generics/form/TabbableForm';
import uniqueId from 'lodash/uniqueId';
import useTextFieldUtils from '../hooks/useTextFieldUtils';
import { EventOrValueHandler, WrappedFieldMetaProps } from 'redux-form';
import renderTextFieldLabel from 'fieldFactory/util/renderTextFieldLabel';

interface Classes {
    //     inputLabelDefault: React.CSSProperties;
}
const styles: (theme: Theme) => Classes = (theme: Theme) => ({
    inputLabelDefault: {
        // color: grey[900], this was done thorugh overrides on MuiInputs.
        // (leaving as an example of overriding classes on a component basis)
    },
});

export const LongTextInput: React.FC<{
    classes: { [key in keyof Classes]: string };
    className?: string;
    isRequired?: boolean;
    options?: {};
    source: string;
    resource: string;
    input?: {
        onBlur: () => void;
        onChange: () => void;
        value: string;
    };
    meta: WrappedFieldMetaProps;
    label?: string;
    addField?: boolean;
    elStyle?: {};
    validate: Function | Function[];
    fullWidth?: boolean;
    disabled?: boolean;
    rows?: number;
    customStyles?: {};
    renderLabel: boolean;
    ariaInputProps?: {};
    overrideAriaLabel?: string;
    compassEndAdornment?: (onChange: (e: EventOrValueHandler<React.ChangeEvent<any>>) => void) => React.ReactNode;
}> = ({
    className,
    input,
    meta,
    isRequired,
    label,
    options,
    source,
    resource,
    disabled,
    classes,
    rows,
    renderLabel,
    ariaInputProps,
    customStyles,
    overrideAriaLabel,
    compassEndAdornment,
}) => {
    const {
        InputPropsClasses,
        createInputLabelProps,
        createFormHelperTextProps,
        muiErrorProp,
        helperText,
        fieldVariant,
    } = useTextFieldUtils(meta);
    const ariaErrormessageRef = React.useRef(uniqueId('longtext-ErrorMsg'));
    if (typeof meta === 'undefined') {
        throw new Error(
            "The LongTextInput component wasn't called within a redux-form <Field>. " +
                'Did you decorate it and forget to add the addField prop to your component? ' +
                'See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component' +
                ' for details.',
        );
    }
    const { touched, error } = meta;
    const InputProps =
        touched && error
            ? { ...ariaInputProps, 'aria-errormessage': ariaErrormessageRef.current }
            : { ...ariaInputProps, 'aria-errormessage': undefined };
    if (label && typeof label === 'string' && label.trim()) {
        InputProps['aria-label'] = label;
    }
    if (overrideAriaLabel) {
        InputProps['aria-label'] = overrideAriaLabel;
    }

    // Lets track the display value here to prevent react jumping the cursor.
    // https://giacomocerquone.com/keep-input-cursor-still/
    const position = useRef({
        beforeStart: 0,
        beforeEnd: 0,
    });
    const inputRef = useRef<HTMLInputElement>(null);
    const handleChange = (e, onChange) => {
        const beforeStart = e.target.selectionStart;
        const beforeEnd = e.target.selectionEnd;
        position.current = {
            beforeStart,
            beforeEnd,
        };

        onChange(e);
    };

    useLayoutEffect(() => {
        inputRef.current.setSelectionRange(position.current.beforeStart, position.current.beforeEnd);
    }, [input.value]);

    return (
        <activeTabContext.Consumer>
            {(tabContext) => (
                <DebouncedTextInput
                    key={tabContext._type} // Remount when 'hidden' or 'shown' because heights need to be recalculated.
                    emptyInitialValue=""
                    input={input}
                    renderInput={({ value, onChange, onBlur }) => (
                        <TextField
                            inputRef={inputRef}
                            InputLabelProps={createInputLabelProps({
                                disabled: false,
                            })}
                            variant={fieldVariant}
                            InputProps={{
                                inputProps: InputProps,
                                classes: InputPropsClasses,
                                endAdornment: compassEndAdornment?.(onChange),
                            }}
                            value={value}
                            onChange={(e) => handleChange(e, onChange)}
                            onBlur={onBlur}
                            className={className}
                            multiline={true}
                            margin="none"
                            rows={rows} // height calculation fails
                            label={renderTextFieldLabel(fieldVariant, renderLabel, isRequired, label)}
                            error={muiErrorProp}
                            helperText={helperText}
                            FormHelperTextProps={createFormHelperTextProps(InputProps)}
                            disabled={disabled}
                            {...options}
                            style={{ minHeight: '15px', ...customStyles }}
                        />
                    )}
                />
            )}
        </activeTabContext.Consumer>
    );
};

LongTextInput.defaultProps = {
    options: {},
    fullWidth: true,
    addField: true,
    disabled: false,
    ariaInputProps: {},
    renderLabel: true,
};

export default withStyles(styles as any)(LongTextInput);
