import { v4 as uuidv4 } from 'uuid';
import { ChatResponseWrapper } from 'chatbot/components/ResponseBox';
import { useEffect, useState } from 'react';
import { Box, IconButton, LinearProgress } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { AssistantType, REPORT_BUILDER } from 'chatbot/constants';
import PossibleInputDisplay from './PossibleInputDisplay';
import { useChatBot } from 'chatbot/ChatbotProvider';
import { CurrentReportType } from 'chatbot/chatbotTypes';
import { Close } from '@mui/icons-material';
import useViewConfig from 'util/hooks/useViewConfig';
import { assistantConfig, createRequestBody, sendRequest } from 'chatbot/utils/sendRequest';

interface AiChatResponseProps {
    payload: { message: string; updateState: (message: any) => void };
    state: { assistant: string; threadId: string };
}

interface AiResponseType {
    output: string;
    newConfig?: { definition: string };
}

const AiChatResponse: React.FC<AiChatResponseProps> = ({ payload, state }) => {
    const [response, setResponse] = useState<AiResponseType | null>(null);
    const viewConfig = useViewConfig();

    const {
        enableInputSendButton,
        disableInputSendButton,
        state: chatState,
        setPrevValue,
        addToHistory,
    } = useChatBot();
    const [abortController, setAbortController] = useState<AbortController | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const messageId = uuidv4();
                const controller = new AbortController();
                setAbortController(controller);

                if (!payload?.message || !state.threadId) {
                    setResponse({ output: 'I need more information than that.' });
                    return;
                }

                let additionalParams: CurrentReportType;
                if (chatState.assistant === REPORT_BUILDER) {
                    additionalParams = chatState.reportParameters;
                    setPrevValue(additionalParams?.currentConfig?.definition);
                }

                addToHistory(state.threadId, {
                    sender: 'user',
                    userId: viewConfig.user.id,
                    messageId: messageId,
                    content: payload.message,
                    additionalContent: additionalParams,
                    timestamp: new Date().toISOString(),
                });

                disableInputSendButton();

                const body = createRequestBody(
                    chatState.assistant,
                    payload.message,
                    state.threadId,
                    messageId,
                    additionalParams,
                );
                const url = assistantConfig[chatState.assistant].getUrl();

                const data = await sendRequest(url, body, controller.signal);
                // there might be more in the response we are looking for
                // to do: refactor with useService
                const handledResponse = assistantConfig[chatState.assistant].handleResponse(data);
                setResponse(handledResponse);

                payload.updateState({
                    id: messageId,
                    message: handledResponse.output,
                    type: `${chatState.assistant}-response`,
                });

                const compassMessage: {
                    sender: 'compass';
                    assistant: AssistantType;
                    messageId: string;
                    content: string;
                    timestamp: string;
                    additionalContent?: string;
                } = {
                    sender: 'compass',
                    assistant: chatState.assistant,
                    messageId: data.message.messageId,
                    content: data.message.parameters.output,
                    timestamp: new Date().toISOString(),
                };
                // if report builder then add additional content to the compass message
                if (chatState.assistant === 'report-builder') {
                    compassMessage.additionalContent = data.message.parameters?.['newConfig'];
                }

                addToHistory(state.threadId, compassMessage);
            } catch (error) {
                let errorMessage = 'Canceled or otherwise failed to fetch response from AI service.';
                if (error.message.includes('Recursion limit of')) {
                    errorMessage = 'Error: Recursion limit reached.';
                }
                setResponse({ output: errorMessage });
                console.error(`Error fetching data: ${error}`);
                addToHistory(state.threadId, {
                    sender: 'compass',
                    assistant: chatState.assistant,
                    messageId: uuidv4(),
                    content: errorMessage,
                    timestamp: new Date().toISOString(),
                });
            } finally {
                setAbortController(null);
                enableInputSendButton();
            }
        };

        fetchData();

        return () => {
            abortController?.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Let's only run this one time per message

    const handleCancel = () => {
        if (abortController) {
            abortController.abort();
            enableInputSendButton();
        }
    };
    const additionalResponseMap = {
        [REPORT_BUILDER]: response?.newConfig?.definition,
    };

    const additionalResponse = additionalResponseMap[chatState.assistant] || null;
    return (
        <ChatResponseWrapper>
            {response ? (
                <ReactMarkdown
                    components={{
                        code({ node, inline, className, children, ...props }) {
                            return (
                                <code
                                    className={className}
                                    style={{
                                        whiteSpace: 'pre-wrap',
                                        wordWrap: 'break-word',
                                        overflowWrap: 'break-word',
                                    }}
                                    {...props}
                                >
                                    {children}
                                </code>
                            );
                        },
                    }}
                >
                    {response.output}
                </ReactMarkdown>
            ) : (
                <Box display="flex" alignItems="center" width={'100%'}>
                    <LinearProgress sx={{ minWidth: 200, mr: 1 }} />
                    <IconButton
                        onClick={handleCancel}
                        size="small"
                        aria-label="cancel"
                        sx={(theme) => ({ color: theme.palette.primary.contrastText })}
                    >
                        <Close />
                    </IconButton>
                </Box>
            )}
            {additionalResponse ? <PossibleInputDisplay additionalParams={additionalResponse} /> : null}
        </ChatResponseWrapper>
    );
};

export default AiChatResponse;
