import React from 'react';
import { IconButton, InputAdornment, useTheme } from '@mui/material';
import { EnabledAndPermissionedWrapper } from 'chatbot/ChatboxFAB';
import CasetivityCompassIcon from 'chatbot/assets/CasetivityCompassLogo';
import { useChatBot } from 'chatbot/ChatbotProvider';
import { getFormValues } from 'redux-form';
import { useAppSelector } from 'reducers/rootReducer';
import { ReportFormType } from 'report-builder/stateManagement/types';

const CompassEndAdornmentForReportBuilder = ({ onChange }: { onChange: (value: string) => void }) => {
    const theme = useTheme();
    const { updateAssistantContext, updateReportContext, updateFieldFiller, state, toggleChat } = useChatBot();
    const formValues = useAppSelector((state) => getFormValues('reportBuilderForm')(state) as ReportFormType);

    const handleClick = () => {
        updateAssistantContext('report-builder');
        updateReportContext({
            currentConfig: formValues,
        });
        updateFieldFiller((value) => {
            onChange(value);
        });
        if (!state.isOpen) {
            toggleChat();
        }
    };

    return (
        <EnabledAndPermissionedWrapper>
            <InputAdornment position="end">
                <IconButton onClick={handleClick}>
                    <CasetivityCompassIcon size="small" pointRight={false} customColor={theme.palette.text.secondary} />
                </IconButton>
            </InputAdornment>
        </EnabledAndPermissionedWrapper>
    );
};

export default CompassEndAdornmentForReportBuilder;
