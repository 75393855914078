import React, { useContext, useMemo } from 'react';
import { RenderListArguments } from 'components/generics/genericList/List';
import { InlineDatagridCoreField } from '../InlineMany/Datagrid';
import { InlineCreateListCoreField } from '../InlineMany/TableWithRepeatedCreateViews';
import { FieldFactoryContext } from 'fieldFactory/Broadcasts';
import { DataSource } from 'fieldFactory/translation/types/DataSource';
import { Mode } from 'fieldFactory/Mode';
import getFields from 'components/generics/genericList/getFields';
import useViewConfig from 'util/hooks/useViewConfig';
import applySortableOverrides from 'components/generics/fields/applySortableOverrides';
import getRenderList from 'components/generics/genericList/renderList';
import { Field, WrappedFieldInputProps } from 'redux-form';
import useEntities from 'util/hooks/useEntities';
import useEntitiesAreLoading from 'util/hooks/useEntitiesAreLoading';
import { Typography } from '@material-ui/core';
import orderBy from 'lodash/orderBy';
import EntityInspect from 'components/generics/hoc/EntityInspect';
import { denormalizeEntitiesByPaths } from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/denormalizing/buildEntityMappingsFromPaths';
import uniqueId from 'lodash/uniqueId';

export interface GetRenderUnpersistedInlineManyConfig {
    viewName?: string;
    entityType?: string;
    id?: string;
    source: string;
    useCreateView?: boolean;
    createViewName?: string;
    unchangingOrderedItems?: boolean;
    label?: React.ReactNode;
    openTo?: 'show' | 'edit';
}

export const RenderExistingListComponent: React.FC<{
    viewName: string;
    reference: string;
    input: Partial<WrappedFieldInputProps>;
    evaluatedAdhocSPELVariables?: Record<string, unknown>;
}> = ({ viewName: _viewName, reference, input, evaluatedAdhocSPELVariables }) => {
    const [sort, setSort] = React.useReducer(
        (state: [field: string, dir: 'ASC' | 'DESC'] | null, field: string): [field: string, dir: 'ASC' | 'DESC'] => {
            if (!state) {
                return [field, 'ASC'];
            }
            const [currField, dir] = state;
            if (field === currField) {
                return [field, dir === 'ASC' ? 'DESC' : 'ASC'];
            }
            return [field, 'ASC'];
        },
        null,
    );

    const viewConfig = useViewConfig();
    const viewName = _viewName ?? viewConfig.entities[reference].defaultViews?.LIST?.name;
    const fieldFactory = useContext(FieldFactoryContext);

    /**
     * Possible TODO:
     * How to eliminate references back to this record:
     * Simple:
     * If there is one reference to the other entity,
     * and one reference back to our entity, remove it.
     *
     * Alternately, a custom view can be made to do this.
     */

    const fields = useMemo(() => {
        const config = {
            dataSource: DataSource.ENTITY,
            mode: Mode.DISPLAY,
            validate: false,
            connected: false,
            options: {
                getOwnData: true,
                hideCheckboxLabel: true,
            },
        };
        return fieldFactory(config)({
            record: {},
            resource: reference,
            basePath: `/${reference}`,
            isForSearch: true,
        })(getFields(viewConfig, viewName /*, referencedFromEntity, referencedByField */)).map(
            applySortableOverrides(reference),
        );
    }, [reference, viewName, fieldFactory, viewConfig]);
    const entities = useEntities();
    const isLoading = useEntitiesAreLoading();

    const data = useMemo(
        () =>
            (input.value || [])
                .filter(({ id }) => id)
                .map(({ id }) => entities[reference]?.[id])
                .filter(Boolean)
                .reduce((prev, curr) => {
                    prev[curr.id] = curr;
                    return prev;
                }, {}),
        [input.value, entities, reference],
    );
    const ids = useMemo(() => {
        const _ids = (input.value || []).map(({ id }) => id).filter(Boolean);
        if (!sort) {
            return _ids;
        }
        const [field, dir] = sort;
        const data = _ids.map((id) => denormalizeEntitiesByPaths(entities, [field], viewConfig, reference, id));

        return orderBy(data, [field], [dir.toLowerCase() as 'asc' | 'desc']).map(({ id }) => id);
    }, [input.value, sort, entities, reference, viewConfig]);

    const internalId = useMemo(() => uniqueId('unpersisted-inlinemany-'), []);

    return (
        <EntityInspect
            evaluatedAdhocSPELVariables={evaluatedAdhocSPELVariables}
            reference={reference}
            formId={internalId}
            renderComponent={(args) =>
                getRenderList(
                    {},
                    {},
                )({
                    fields,
                    data,
                    ids,
                    hasSomeVisibleSearchFields: false,
                    setSort,
                    isLoading,
                    resource: reference,
                    ariaProps: {},
                    onRowSelect: args.onRowSelect,
                })
            }
        />
    );
};
const RenderExistingList = (props) => {
    return <Field {...props} name={props.source} component={RenderExistingListComponent} />;
};

const getRenderUnpersistedInlineList =
    <
        RenderListProps extends {
            resource: string;
            referencedByField?: string;
            data: RenderListArguments['data'];
            onRowSelect?: RenderListArguments['onRowSelect'];
            showCreate?: boolean;
            disabled?: boolean;
            defaultRenderer?: RenderListArguments['defaultRenderer'];
        },
    >(
        config: GetRenderUnpersistedInlineManyConfig,
    ) =>
    (
        props: RenderListProps & {
            renderNoData?: () => JSX.Element;
            evaluatedAdhocSPELVariables?: Record<string, unknown>;
        },
    ) => {
        if (config.useCreateView) {
            return (
                <>
                    <Typography variant="h6">{config.label}</Typography>
                    <RenderExistingList
                        evaluatedAdhocSPELVariables={props.evaluatedAdhocSPELVariables}
                        viewName={config.viewName}
                        reference={props.resource}
                        source={config.source}
                    />
                    <InlineCreateListCoreField
                        evaluatedAdhocSPELVariables={props.evaluatedAdhocSPELVariables}
                        accessLevel={5}
                        resource={props.resource}
                        source={config.source}
                        disabled={props.disabled}
                        createViewName={config.createViewName}
                        renderNoData={props.renderNoData}
                    />
                </>
            );
        }
        return (
            <>
                <Typography variant="h6">{config.label}</Typography>
                <EntityInspect
                    evaluatedAdhocSPELVariables={props.evaluatedAdhocSPELVariables}
                    reference={props.resource}
                    openTo={config.openTo}
                    formId={`${config.source}:${props.resource}`}
                    renderComponent={({ selectId }) => (
                        <InlineDatagridCoreField
                            accessLevel={5}
                            handleSelectExistingRecord={(id) => selectId(id)}
                            referencedByField={props.referencedByField}
                            resource={props.resource}
                            rootEntityType={config.entityType}
                            id={config.id}
                            source={config.source}
                            overrideViewName={config.viewName}
                            showCreate={props.showCreate !== false && !config.unchangingOrderedItems}
                            unchangingOrderedItems={config.unchangingOrderedItems}
                            disabled={props.disabled}
                        />
                    )}
                />
            </>
        );
    };
export default getRenderUnpersistedInlineList;
