import * as React from 'react';
import useViewConfig from 'util/hooks/useViewConfig';
import { FieldViewField } from 'reducers/ViewConfigType';
import { FieldFactoryContext } from 'fieldFactory/Broadcasts';
import { Mode } from 'fieldFactory/Mode';
import { DataSource } from '../../../translation/types/DataSource';
import getFields from 'components/generics/genericList/getFields';
import Table from './CustomDatagrid/CustomDatagrid';
import {
    getLabelForFieldExpr,
    isExpressionViewField,
    isFieldViewField,
} from 'components/generics/utils/viewConfigUtils';
import { IconButton, TableBody, TableCell, TableRow } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import ControlledValidatedForm from './ControlledValidatedRowForm';
import { useAppStore } from 'reducers/rootReducer';
import { crudGetList } from 'sideEffect/crud/getList/actions';
import { refetchAllXManysContext } from 'fieldFactory/display/components/RefmanyMultiselect/util/refetchAllXManysContext';
import { ExpressionCell } from 'components/generics/genericList/renderList';
import getAdhocVariablesContextSelector from 'components/generics/form/EntityFormContext/util/getVariablesContextSelector';
import { formContext } from 'components/generics/form/EntityFormContext/Show';
import formTypeContext from 'components/generics/form/formTypeContext';
import { Clear } from '@material-ui/icons';

export interface InlineDatagridProps {
    source: string;
    id: string; // coming from props.record.id
    rootEntityType: string;
    resource: string;
    filter?: string;
    overrideViewName?: string;
    referencedByField?: string;
    handleSelectExistingRecord: (id: string) => void;
    showCreate?: boolean;
    showDelete?: boolean;
    disabled?: boolean;
}

export interface FieldProps {
    input: {
        onChange: (value: {}[]) => void;
        value?: {}[];
    };
    meta?: {
        submitFailed?: boolean;
        initial?: {}[];
    };
}

export interface InlineDatagridFieldProps extends InlineDatagridProps, FieldProps {}

export const useFetchRowData = (rows: any[], resource: string) => {
    const store = useAppStore();
    const refetchKey = React.useContext(refetchAllXManysContext);
    const ids = React.useMemo(
        () =>
            rows
                .map((r) => r['id'])
                .filter(Boolean)
                .sort()
                .join(','),
        [rows],
    );

    const initialFetchedIdsSet = React.useMemo(() => new Set<string>(), []);
    const fetchedIdsSetRef = React.useRef<Set<string>>(initialFetchedIdsSet);

    /*
     * 1. Keep a set of ids previously fetched
     * 2. add new 'idsToFetch' to that set
     * 2. Fetch everything in the new set
     * 3. remove everything in that set which then is not in our current result set.
     */

    React.useEffect(() => {
        ids.split(',').forEach((id) => {
            fetchedIdsSetRef.current.add(id);
        });
        store.dispatch(
            crudGetList({
                cb: ({ response }) => {
                    const foundIds = response.reduce((prev, { id }) => {
                        prev[id] = true;
                        return prev;
                    }, {} as { [id: string]: true });
                    Array.from(fetchedIdsSetRef.current.values()).forEach((fetchedId) => {
                        if (!foundIds[fetchedId]) {
                            fetchedIdsSetRef.current.delete(fetchedId);
                        }
                    });
                },
                resource,
                filter: {
                    id__IN: Array.from(fetchedIdsSetRef.current.values()).join(','),
                },
                view: null,
                pagination: {
                    page: 1,
                    perPage: ids.length,
                },
                sort: {
                    field: 'id',
                    order: 'ASC',
                },
            }),
        );
    }, [ids, store, resource, refetchKey]);
};

export const NewRowExpressionCell: React.FC<{
    rootEntityType: string;
    record: Record<string, unknown>;
}> = ({ record, children }) => {
    const adhocVariablesContext = React.useMemo(getAdhocVariablesContextSelector, []);
    return (
        <formTypeContext.Provider value="SHOW">
            {/* faking a Show view, so the Expression field works with the data we provide here */}
            <formContext.Provider
                value={{
                    fieldValues: record,
                    hiddenFields: {},
                    valuesetFieldAvailableConceptIds: {},
                    viewName: null,
                    adhocVariablesContext: adhocVariablesContext({}),
                    variables: {},
                }}
            >
                <div style={{ marginLeft: '1em' }}>{children}</div>
            </formContext.Provider>
        </formTypeContext.Provider>
    );
};

export interface InlineDatagridCoreProps {
    id: string; // coming from props.record.id
    rootEntityType: string;
    resource: string;
    overrideViewName?: string;
    referencedByField?: string;
    handleSelectExistingRecord?: (id: string) => void;
    showCreate?: boolean;
    disabled?: boolean;
    rows?: {
        id: string;
        [key: string]: unknown;
    }[];
    accessLevel: number;
    unchangingOrderedItems?: boolean;
}

const emptyObject = {};

export const useEditableRows = (props: {
    id: string; // coming from props.record.id
    rootEntityType: string;
    resource: string;
    overrideViewName?: string;
    referencedByField?: string;
    disabled?: boolean;
}) => {
    const { overrideViewName, resource, referencedByField, rootEntityType, id, disabled } = props;
    const viewConfig = useViewConfig();

    const listViewName = overrideViewName ?? viewConfig.entities[resource]?.defaultViews?.LIST?.name;

    const fieldFactory = React.useContext(FieldFactoryContext);
    const fieldElemDefinitionTuples: [any, FieldViewField, any][] = React.useMemo(() => {
        const fields = getFields(viewConfig, listViewName, true, referencedByField);
        const inputFields = fieldFactory({
            // TODO: figure out validation.
            dataSource: DataSource.ENTITY,
            mode: Mode.INPUT_NOWARN,
            validate: true,
            connected: 'ff',
            options: {
                getOwnData: true,
                hideCheckboxLabel: true,
                ff: true,
            },
        })({ record: { id, entityType: rootEntityType }, resource, basePath: '/' + resource })(fields);
        return fieldFactory({
            dataSource: DataSource.ENTITY,
            mode: Mode.DISPLAY,
            validate: false,
            connected: false,
            options: {
                getOwnData: true,
                hideCheckboxLabel: true,
            },
        })({ record: { id, entityType: rootEntityType }, resource, basePath: '/' + resource, isForSearch: true })(
            fields,
        ).map((f, i) => [f, fields[i], inputFields[i]] as [any, FieldViewField, any]);
    }, [viewConfig, listViewName, referencedByField, id, rootEntityType, resource, fieldFactory]);

    const renderNewRowForm = (props: {
        submitFailed: boolean;
        initialRowValues: Record<string, unknown>;
        rowValues: Record<string, unknown>;
        onChangeRowValues: (value: Record<string, unknown>) => void;
        handleDelete?: () => void;
    }) => {
        const { submitFailed, initialRowValues, onChangeRowValues, rowValues, handleDelete } = props;
        return (
            <ControlledValidatedForm
                submitFailed={submitFailed}
                resource={resource}
                registeredFields={fieldElemDefinitionTuples.map(([, f]) => f.field)}
                initialValues={initialRowValues ?? emptyObject}
                values={rowValues}
                setValues={onChangeRowValues}
            >
                {(props) => {
                    return (
                        <>
                            {fieldElemDefinitionTuples.map(([displayE, f, inputE]) => (
                                <TableCell
                                    component="td"
                                    style={{
                                        paddingTop: '16px',
                                        paddingBottom: '16px',
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    }}
                                    key={f.field}
                                >
                                    {isExpressionViewField(f) ? (
                                        <NewRowExpressionCell record={rowValues} rootEntityType={resource}>
                                            {React.cloneElement(displayE, { record: rowValues })}
                                        </NewRowExpressionCell>
                                    ) : (
                                        React.cloneElement(inputE, { record: rowValues })
                                    )}
                                </TableCell>
                            ))}
                            {!disabled && (
                                <TableCell style={{ paddingLeft: 0, paddingRight: 0 }}>
                                    {handleDelete && (
                                        <div
                                            style={{
                                                display: 'inline-flex',
                                                flex: '0 0 auto',
                                                textAlign: 'center',
                                                alignItems: 'center',
                                                verticalAlign: 'middle',
                                                height: '100%',
                                                marginLeft: '1em',
                                            }}
                                        >
                                            <div style={{ margin: '-14px' }}>
                                                <IconButton aria-label="Remove Row" onClick={handleDelete}>
                                                    <Clear />
                                                </IconButton>
                                            </div>
                                        </div>
                                    )}
                                </TableCell>
                            )}
                        </>
                    );
                }}
            </ControlledValidatedForm>
        );
    };
    const renderExistingRowCells = ({
        record,
        handleSelectExistingRecord,
        isReadOnlyNewRow = false,
    }: {
        record: Record<string, unknown>;
        handleSelectExistingRecord?: (id: string) => void;
        isReadOnlyNewRow?: boolean;
    }) => {
        return (
            <>
                {fieldElemDefinitionTuples.map(([e, f]) => (
                    <TableCell
                        component="td"
                        className="td-data"
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                        key={f.field}
                    >
                        <div
                            style={{
                                display: 'inline-flex',
                                flex: '0 0 auto',
                                textAlign: 'center',
                                alignItems: 'center',
                                verticalAlign: 'middle',
                                height: '100%',
                                width: '100%',
                            }}
                        >
                            <div
                                style={{
                                    display: 'block',
                                    textAlign: 'left',
                                    marginLeft: '1em',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    width: '100%',
                                }}
                            >
                                {(() => {
                                    if (!isExpressionViewField(f)) {
                                        return React.cloneElement(e, { record });
                                    }
                                    if (isReadOnlyNewRow) {
                                        return (
                                            <span style={{ marginLeft: '-1em' }}>
                                                <NewRowExpressionCell record={record} rootEntityType={resource}>
                                                    {React.cloneElement(e, { record })}
                                                </NewRowExpressionCell>
                                            </span>
                                        );
                                    }
                                    return (
                                        <ExpressionCell
                                            rootEntityId={record['id'] as string}
                                            rootEntityType={resource}
                                            expression={f.config}
                                        >
                                            {React.cloneElement(e, { record })}
                                        </ExpressionCell>
                                    );
                                })()}
                            </div>
                        </div>
                    </TableCell>
                ))}
                {!disabled && (
                    <TableCell component="td" style={{ paddingLeft: 0, paddingRight: 0 }}>
                        {handleSelectExistingRecord && (
                            <div
                                style={{
                                    display: 'inline-flex',
                                    flex: '0 0 auto',
                                    textAlign: 'center',
                                    alignItems: 'center',
                                    verticalAlign: 'middle',
                                    height: '100%',
                                    marginLeft: '1em',
                                }}
                            >
                                <div style={{ margin: '-14px' }}>
                                    <IconButton
                                        aria-label="Edit Row"
                                        onClick={() => {
                                            handleSelectExistingRecord(record['id'] as string);
                                        }}
                                    >
                                        <Edit />
                                    </IconButton>
                                </div>
                            </div>
                        )}
                    </TableCell>
                )}
            </>
        );
    };
    return {
        renderExistingRowCells,
        renderNewRowForm,
        headers: [
            ...fieldElemDefinitionTuples.map(([, f]) => ({
                label:
                    f.label ??
                    (() => {
                        if (!isFieldViewField(f)) {
                            return null;
                        }
                        return getLabelForFieldExpr(viewConfig, resource, f.field, 'TRAVERSE_PATH');
                    })(),
                field: f.field,
            })),
            ...(!disabled
                ? [
                      {
                          label: '',
                      },
                  ]
                : []),
        ],
    };
};

export function InlineStableDatagridCore({
    id,
    rootEntityType,
    resource,
    overrideViewName,
    referencedByField,
    input: { value: rows, onChange },
    handleSelectExistingRecord,
    meta,
    disabled,
}: InlineDatagridCoreProps & FieldProps) {
    const { renderExistingRowCells, renderNewRowForm, headers } = useEditableRows({
        id,
        rootEntityType,
        resource,
        referencedByField,
        overrideViewName,
        disabled,
    });
    const initialValue = React.useMemo(() => {
        if (Array.isArray(meta.initial)) {
            return meta.initial;
        }
        return [];
    }, [meta.initial]);

    const submitFailed = meta?.submitFailed;

    const TableContent = (
        <TableBody>
            {(rows || []).map((r, i) => {
                if (!r['id'] && !disabled) {
                    return (
                        <TableRow key={i}>
                            {renderNewRowForm({
                                initialRowValues: initialValue[i],
                                rowValues: r,
                                onChangeRowValues: disabled
                                    ? undefined
                                    : (r) => {
                                          onChange([
                                              ...(i > 0 ? rows.slice(0, i) : []),
                                              r as { _key: string },
                                              ...(i !== rows.length - 1 ? rows.slice(i + 1) : []),
                                          ]);
                                      },
                                submitFailed,
                            })}
                        </TableRow>
                    );
                }
                return (
                    <TableRow key={'existing -' + i}>
                        {renderExistingRowCells({
                            record: r,
                            handleSelectExistingRecord,
                            isReadOnlyNewRow: !r['id'],
                        })}
                    </TableRow>
                );
            })}
        </TableBody>
    );

    return (
        <div>
            <Table headers={headers} minCellWidth={120} tableContent={TableContent} />
        </div>
    );
}
